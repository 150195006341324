import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ActionButtons as ActionButtonsBase } from 'components/SideFilters/ActionButtons';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type ActionButtonsProps = {
  className?: string;
  onFiltersClose?: VoidFunction;
};

const ActionButtons = ({ className, onFiltersClose }: ActionButtonsProps): React.ReactElement<ActionButtonsProps> => {
  const { filters } = useLotListStore();

  const handleFilterChange = React.useCallback(() => {
    filters.setSideFilters();
    onFiltersClose?.();
  }, [onFiltersClose]);

  return (
    <ActionButtonsBase
      className={className}
      isEmptySideFilters={filters.isEmptySideFilters}
      onClearSideFilters={filters.clearSideFilters}
      onSetSideFilters={handleFilterChange}
      isLoading={filters.loadingFiltersStage.isLoading}
    />
  );
};

export default observer(ActionButtons);
