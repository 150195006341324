import cn from 'classnames';
import * as React from 'react';

import { PageContainer } from 'components/PageContainer';
import { PageListTitle } from 'components/PageListTitle';
import { AnalyticsEvent } from 'entities/analytics';
import useToggleState from 'hooks/useToggleState';
import { useWidth } from 'hooks/useWidth';
import { LotListStoreProvider } from 'stores/LotListCatalogStore/context';
import { useRootStore } from 'stores/RootStore';

import { ListWithFilters } from './ListWithFilters';
import { ObjectTypeTabbar } from './ObjectTypeTabbar';
import { SearchBar } from './SearchBar';
import { TotalItemsCount } from './TotalItemsCount';

import s from './LotsListPage.module.scss';

type Props = {
  className?: string;
};

const LotsListPage: React.FC<Props> = ({ className }) => {
  const { analyticsStore } = useRootStore();
  const { opened, toggle: toggleFiltersCollapsed } = useToggleState();
  const { isPhone } = useWidth();

  const isFiltersCollapsed = isPhone ? opened : false;

  React.useEffect(() => {
    analyticsStore.trackEvent({
      event: AnalyticsEvent.lotsListPageViewed,
    });
  }, []);

  return (
    <PageContainer withoutBottomPadding className={cn(s['lots-list-page'], className)}>
      <PageListTitle opened={isFiltersCollapsed} toggle={toggleFiltersCollapsed}>
        Каталог услуг
      </PageListTitle>
      <LotListStoreProvider>
        {!isFiltersCollapsed && (
          <>
            <SearchBar />
            <ObjectTypeTabbar />
            <TotalItemsCount />
          </>
        )}
        <ListWithFilters isFiltersCollapsed={isFiltersCollapsed} />
      </LotListStoreProvider>
    </PageContainer>
  );
};

export default LotsListPage;
