import { E164Number } from 'libphonenumber-js/core';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { Validator, ValidatorResult } from 'types/validator';

export const phoneValidator: Validator<E164Number | null> = (value: E164Number | null): ValidatorResult => {
  if (!value) {
    return null;
  }

  if (!isPossiblePhoneNumber(value)) {
    return 'Введён неверный формат номера';
  }

  return null;
};

export const emailValidator: Validator<string> = (value: string): ValidatorResult => {
  if (
    !value.match(
      /^[-!#$%&'*+=?^`{}|~\w]+(?:\.[-!#$%&'*+=?^`{}|~\w]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,6})/gm,
    )
  ) {
    return 'Некорректный email';
  }

  return null;
};

type StringValidatorCreator = (params: { minLength?: number; maxLength?: number }) => Validator<string>;

export const stringLengthValidator: StringValidatorCreator =
  ({ minLength = 0, maxLength }) =>
  (value: string): ValidatorResult => {
    if (minLength && value.length < minLength) {
      return `Минимальное количество символов — ${minLength}`;
    }

    if (maxLength && value.length > maxLength) {
      return `Максимальное количество символов — ${maxLength}`;
    }

    return null;
  };
