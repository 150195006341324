import { Menu } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMenuKeys } from 'hooks/useMenuKeys';

import { headerMenuItems } from './config';

import s from './HeaderMenu.module.scss';

type HeaderMenuProps = {
  className?: string;
};

type MenuEventHandler = NonNullable<React.ComponentProps<typeof Menu>['onClick']>;

const HeaderMenu = ({ className }: HeaderMenuProps): React.ReactElement<HeaderMenuProps> => {
  const navigate = useNavigate();
  const selectedKeys = useMenuKeys();

  const handleClickMenuItem = React.useCallback<MenuEventHandler>((e) => {
    navigate(e.key);
  }, []);

  return (
    <Menu
      className={cn(s['header-menu'], className)}
      onClick={handleClickMenuItem}
      selectedKeys={selectedKeys}
      mode="horizontal"
      items={headerMenuItems}
    />
  );
};

export default React.memo(HeaderMenu);
