import * as React from 'react';

import { bidStatusConfig, bidTypeConfig } from 'entities/bid';
import { Status } from 'entities/lot';
import { useWidth } from 'hooks/useWidth';
import { BidModel } from 'models/BidModel';

import { LotCard, LotCardProps } from '../LotCard';
import MobileLotCard from '../MobileLotCard';

type BidCardProps = {
  item: BidModel;
  className?: string;
};

const BidCard: React.FC<BidCardProps> = ({ item, className }) => {
  const { isDesktop } = useWidth();

  const additionalTags: Status[] = [bidStatusConfig[item.status], bidTypeConfig[item.type]];

  const props = React.useMemo<LotCardProps>(
    () => ({
      className,
      item: item.lot,
      additionalTags,
    }),
    [],
  );

  if (isDesktop) {
    return <LotCard {...props} />;
  }

  return <MobileLotCard {...props} />;
};

export default React.memo(BidCard);
