import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { TotalItemsCount as TotalItemsCountBase } from 'components/TotalItemsCount';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { capitalize } from 'utils/capitalize';
import { pluralizePreselectedWithoutCount } from 'utils/pluralizer';

type TotalItemsCountProps = {
  className?: string;
};

const TotalItemsCount = ({ className }: TotalItemsCountProps): React.ReactElement<TotalItemsCountProps> => {
  const { lots } = useLotListStore();
  const prefix = capitalize(pluralizePreselectedWithoutCount(lots.total.value ?? 0, 'найден'));

  return <TotalItemsCountBase className={className} itemsCount={lots.total.value} prefix={prefix} />;
};

export default observer(TotalItemsCount);
