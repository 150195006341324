import { ConfigProvider } from 'antd';
import * as React from 'react';
import { RouterProvider } from 'react-router-dom';

import { router } from 'config/routes';
import { themeConfig } from 'config/themeConfig';

const Root = (): React.ReactElement => {
  return (
    <ConfigProvider theme={themeConfig}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default Root;
