import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Tags from 'components/Tags';
import { useLotPageStore } from 'stores/LotPageStore';

type TagsProps = {
  className?: string;
};

const LotPageTags: React.FC<TagsProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  return <Tags className={className} status={lot.value.status} auctionFormat={lot.value.auctionFormat} />;
};

export default observer(LotPageTags);
