import animateScrollTo from 'animated-scroll-to';

export const scrollToSelector = ({
  elSelector,
  containerSelector,
  verticalOffset,
}: {
  elSelector: string;
  containerSelector?: string;
  verticalOffset?: number;
}) => {
  const element: HTMLElement | null = document.querySelector(elSelector);
  let container: Element | Window = window;

  if (containerSelector) {
    container = document.querySelector(containerSelector) || window;
  }

  if (!element || !container) {
    return;
  }

  animateScrollTo(element, { elementToScroll: container, verticalOffset: verticalOffset ?? 0 });
};
