import { SearchOutlined } from '@ant-design/icons';
import { Input, Select, Spin, Typography } from 'antd';
import * as React from 'react';

import { FilterOption } from 'entities/lotFilters';
import { pluralizePreselected } from 'utils/pluralizer';

import { MultiSelectItem } from './MultiSelectItem';
import { SelectedItem } from './SelectedItem';

import s from './MultiSelect.module.scss';

type MultiSelectProps = {
  options: FilterOption[];
  selectedOptions: FilterOption[];
  value: FilterOption['id'][];
  onChange: (id: FilterOption['id']) => void;
  isOptionsLoading: boolean;
  withSearch?: boolean;
  onSearch?: (value: string) => void;
  searchValue?: string;
  className?: string;
};

const MultiSelect = ({
  options,
  selectedOptions,
  value,
  onChange,
  isOptionsLoading,
  withSearch = true,
  onSearch,
  searchValue,
  className,
}: MultiSelectProps): React.ReactElement<MultiSelectProps> => {
  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearch?.(e.target.value);
    },
    [onSearch],
  );

  return (
    <div className={className}>
      <Select
        className={s['multi-select__select']}
        dropdownRender={() => (
          <>
            {withSearch && (
              <Input
                className={s['multi-select__search']}
                placeholder="Поиск"
                value={searchValue}
                onChange={handleSearch}
                suffix={<SearchOutlined className={s['multi-select__search-icon']} />}
                allowClear
              />
            )}
            <div className={s['multi-select__items-wrapper']}>
              {isOptionsLoading && <Spin size="small" />}
              {!isOptionsLoading && options.length === 0 && (
                <Typography.Text className={s['multi-select__item_placeholder']}>Нет доступных опций</Typography.Text>
              )}
              {!isOptionsLoading &&
                options.map((option) => (
                  <MultiSelectItem
                    key={option.id}
                    option={option}
                    onChange={onChange}
                    checked={value.includes(option.id)}
                    className={s['multi-select__item']}
                  />
                ))}
            </div>
          </>
        )}
        placeholder="Не выбрано"
        value={
          selectedOptions.length > 0 ? `Выбрано ${pluralizePreselected(selectedOptions.length, 'значение')}` : null
        }
      />
      {selectedOptions.length > 0 && (
        <div className={s['multi-select__selected-items-wrapper']}>
          {selectedOptions.map((option) => (
            <SelectedItem key={option.id} option={option} onChangeItem={onChange} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
