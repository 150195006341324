import * as React from 'react';

import ImgCarousel, { CarouselImageSize } from 'components/ImgCarousel';
import { ElementId } from 'config/ids';
import LotPageTags from 'pages/LotPage/LotPageTags';
import { useLotPageStore } from 'stores/LotPageStore';

import s from './MobileCarousel.module.scss';

const MobileCarousel: React.FC = () => {
  const { lot } = useLotPageStore();

  // Скрытие тегов лота, которые отображаются поверх галереи, при открытии фото на полный экран
  const handleFullscreen = React.useCallback((isFullscreen: boolean) => {
    const tags: NodeListOf<HTMLElement> = document.querySelectorAll('.hidable-tag');

    if (tags) {
      tags.forEach((tag) => {
        if (isFullscreen) {
          tag.classList.add(s['carousel_hidden-tag']);
        } else {
          tag.classList.remove(s['carousel_hidden-tag']);
        }
      });
    }
  }, []);

  return (
    <div className={s.carousel} id={ElementId.lotPageImageCarouselMobile}>
      <LotPageTags className={s.carousel__tags} />
      <ImgCarousel
        images={lot.value.images}
        handleFullscreen={handleFullscreen}
        imgSize={CarouselImageSize.l}
        withoutThumbnails
      />
    </div>
  );
};

export default React.memo(MobileCarousel);
