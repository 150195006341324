import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { AreaText } from 'components/AreaText';
import Tags from 'components/Tags';
import { ViewsCount } from 'components/ViewsCount';
import { DowngradeIcon } from 'components/icons/DowngradeIcon';
import { LotStatus, Status } from 'entities/lot';

import s from './Header.module.scss';

type HeaderProps = {
  status: LotStatus;
  auctionFormat: string;
  additionalTags?: Status[];
  auctionType: string;
  objectType: string;
  totalArea: string | null;
  priceMin: string | null;
  viewsCount: number | null;
  isDowngradeAuction: boolean;
  className?: string;
};

const Header = ({
  status,
  auctionFormat,
  auctionType,
  objectType,
  totalArea,
  priceMin,
  viewsCount,
  isDowngradeAuction,
  additionalTags,
  className,
}: HeaderProps): React.ReactElement<HeaderProps> => {
  return (
    <div className={cn(s.header, className)}>
      <div className={s.header__main}>
        <Tags
          className={s['header__tags-container']}
          status={status}
          auctionFormat={auctionFormat}
          additionalTags={additionalTags}
        />
        <Typography.Title level={3} className={s['header__title']}>
          <span className={cn(s['header__title-object-type'], s['header__text-accent'])}>{objectType}</span>
          <AreaText area={totalArea} />
          {isDowngradeAuction && <DowngradeIcon className={s['header__downgrade-icon']} />}
        </Typography.Title>
        <Typography.Text className={s['header__auction-type']}>{auctionType}</Typography.Text>
      </div>
      <div className={s.header__aside}>
        <ViewsCount className={s['header__views-count']} viewsCount={viewsCount ?? 0} />
        {priceMin && (
          <Typography.Paragraph className={cn(s['header__price'], s['header__text-accent'])}>
            {priceMin}
          </Typography.Paragraph>
        )}
      </div>
    </div>
  );
};

export default React.memo(Header);
