import { Tag as TagAnt } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './Tag.module.scss';

type TagProps = React.PropsWithChildren<{
  className?: string;
}> &
  React.ComponentProps<typeof TagAnt>;

const Tag = ({ className, children, ...rest }: TagProps): React.ReactElement<TagProps> => {
  return (
    <TagAnt className={cn(s.tag, 'hidable-tag', className, s[`tag_color_${rest.color}`])} {...rest}>
      {children}
    </TagAnt>
  );
};

export default Tag;
