import * as React from 'react';

import { BaseIconProps } from '../commonProps';

type ProfileIconProps = BaseIconProps;

const ProfileIcon = (props: ProfileIconProps): React.ReactElement<ProfileIconProps> => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block' }}
    >
      <rect width="32" height="32" rx="16" fill="currentColor" />
      <path
        d="M21.4136 19.9312C21.1189 19.2331 20.6911 18.5989 20.1542 18.0641C19.619 17.5277 18.9849 17.1 18.2871 16.8047C18.2808 16.8016 18.2746 16.8 18.2683 16.7969C19.2417 16.0938 19.8746 14.9484 19.8746 13.6562C19.8746 11.5156 18.1402 9.78125 15.9996 9.78125C13.8589 9.78125 12.1246 11.5156 12.1246 13.6562C12.1246 14.9484 12.7574 16.0937 13.7308 16.7984C13.7246 16.8016 13.7183 16.8031 13.7121 16.8062C13.0121 17.1016 12.3839 17.525 11.8449 18.0656C11.3085 18.6009 10.8808 19.235 10.5855 19.9328C10.2954 20.616 10.1389 21.3485 10.1246 22.0906C10.1241 22.1073 10.1271 22.1239 10.1332 22.1394C10.1392 22.1549 10.1484 22.1691 10.16 22.181C10.1717 22.193 10.1856 22.2025 10.201 22.2089C10.2164 22.2154 10.2329 22.2188 10.2496 22.2188H11.1871C11.2558 22.2188 11.3105 22.1641 11.3121 22.0969C11.3433 20.8906 11.8277 19.7609 12.6839 18.9047C13.5699 18.0188 14.7464 17.5312 15.9996 17.5312C17.2527 17.5312 18.4292 18.0188 19.3152 18.9047C20.1714 19.7609 20.6558 20.8906 20.6871 22.0969C20.6886 22.1656 20.7433 22.2188 20.8121 22.2188H21.7496C21.7662 22.2188 21.7827 22.2154 21.7981 22.2089C21.8135 22.2025 21.8274 22.193 21.8391 22.181C21.8507 22.1691 21.8599 22.1549 21.8659 22.1394C21.872 22.1239 21.875 22.1073 21.8746 22.0906C21.8589 21.3438 21.7042 20.6172 21.4136 19.9312ZM15.9996 16.3438C15.2824 16.3438 14.6074 16.0641 14.0996 15.5563C13.5917 15.0484 13.3121 14.3734 13.3121 13.6562C13.3121 12.9391 13.5917 12.2641 14.0996 11.7562C14.6074 11.2484 15.2824 10.9688 15.9996 10.9688C16.7167 10.9688 17.3917 11.2484 17.8996 11.7562C18.4074 12.2641 18.6871 12.9391 18.6871 13.6562C18.6871 14.3734 18.4074 15.0484 17.8996 15.5563C17.3917 16.0641 16.7167 16.3438 15.9996 16.3438Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileIcon;
