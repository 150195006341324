import { CloseOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './Header.module.scss';

type HeaderProps = {
  onFiltersClose: VoidFunction;
  className?: string;
};

const Header = ({ onFiltersClose, className }: HeaderProps): React.ReactElement<HeaderProps> => {
  return (
    <div className={cn(s['header'], className)}>
      <Button onClick={onFiltersClose} type="text" icon={<CloseOutlined />} className={s['header__close-button']} />
      <Typography.Title className={s['header__title']} level={2}>
        Фильтры
      </Typography.Title>
    </div>
  );
};

export default React.memo(Header);
