import { IReactionDisposer, computed, makeObservable, reaction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { BidServer } from 'entities/bid';
import { LotServer, ProfileLotsResponse } from 'entities/lot';
import { ProfileListPayload, ProfileListType } from 'entities/lotFilters';
import { BidModel } from 'models/BidModel';
import { ListModel } from 'models/ListModel';
import { LotModel } from 'models/LotModel';
import { LocalStore } from 'stores/LocalStore';
import { IRootStore } from 'stores/RootStore';
import { AppEvent } from 'types/appEvent';
import { api } from 'utils/api';

import { LotListProfileFiltersModel } from './LotListProfileFiltersModel';

type LotListProfileStoreParams = {
  queryParams: URLSearchParams;
  rootStore: IRootStore;
};

export class LotListProfileStore extends LocalStore {
  readonly favoriteLots: ListModel<LotModel, number> = new ListModel<LotModel, number>();
  readonly bids: ListModel<BidModel, number> = new ListModel<BidModel, number>();
  readonly subscriptionLots: ListModel<LotModel, number> = new ListModel<LotModel, number>();
  readonly filters: LotListProfileFiltersModel;

  private readonly rootStore: IRootStore;

  private readonly disposers: IReactionDisposer[] = [];

  constructor({ queryParams, rootStore }: LotListProfileStoreParams) {
    super();

    makeObservable<this>(this, {
      isListInitialLoading: computed,
      currentTotalItems: computed,
    });

    this.filters = new LotListProfileFiltersModel({ params: queryParams, routerStore: rootStore.routerStore });
    this.filters.init();
    this.rootStore = rootStore;

    this.subscribe(AppEvent.lotRemoveFromFavorites, ({ lotId }) => {
      this.favoriteLots.removeEntity(lotId);
      const updatedTotal = this.favoriteLots.total.value ? this.favoriteLots.total.value - 1 : 0;

      this.favoriteLots.total.change(updatedTotal);
      this.favoriteLots.totalPure.change(updatedTotal);
    });

    this.subscribe(AppEvent.lotUnsubscribe, ({ lotId }) => {
      this.subscriptionLots.removeEntity(lotId);
      const updatedTotal = this.subscriptionLots.total.value ? this.subscriptionLots.total.value - 1 : 0;

      this.subscriptionLots.total.change(updatedTotal);
      this.subscriptionLots.totalPure.change(updatedTotal);
    });

    this.disposers.push(
      reaction(
        () => this.filters.offset,
        () => this.fetchCurrentListItems(),
      ),
      reaction(
        () => this.filters.params,
        () => this.fetchCurrentListItems(),
      ),
      reaction(
        () => this.filters.isEmptySideFilters,
        (isEmptySideFilters) => {
          // Ресетим фильтры, если все теги были удалены не через кнопку очистить фильтры
          if (isEmptySideFilters) {
            this.filters.setSideFilters();
          }
        },
      ),
    );
  }

  get isListInitialLoading(): boolean {
    return (
      (this.filters.listType.selectedValue.value === ProfileListType.favorites &&
        this.favoriteLots.isInitialLoading.value) ||
      (this.filters.listType.selectedValue.value === ProfileListType.bids && this.bids.isInitialLoading.value) ||
      (this.filters.listType.selectedValue.value === ProfileListType.subs &&
        this.subscriptionLots.isInitialLoading.value)
    );
  }

  get currentTotalItems(): number {
    switch (this.filters.listType.selectedValue.value) {
      case ProfileListType.favorites:
        return this.favoriteLots.total.value ?? 0;

      case ProfileListType.bids:
        return this.bids.total.value ?? 0;

      case ProfileListType.subs:
        return this.subscriptionLots.total.value ?? 0;
      default:
        return 0;
    }
  }

  private preparePayload = (): ProfileListPayload => {
    return {
      params: {
        limit: this.filters.limit,
        offset: this.filters.offset,
        order: this.filters.params.order,
        order_direction: this.filters.params.order_direction,
      },
      list_type: this.filters.params.list_type,
      filters: {
        auction_type: this.filters.params.auction_type,
        form_type: this.filters.params.form_type,
        status: this.filters.params.status,
      },
    };
  };

  fetchFavoriteLotItems = async ({ replace = false }: { replace: boolean }): Promise<void> => {
    if (replace) {
      this.favoriteLots.setIsAllLoaded(false);
      this.favoriteLots.isReplaceLoading.change(true);
    }

    if (this.favoriteLots.loadingStage.isLoading || this.favoriteLots.isAllLoaded) {
      return;
    }

    this.favoriteLots.loadingStage.loading();

    const response = await api<ProfileLotsResponse<LotServer>>({
      url: apiUrls.lotListProfile,
      method: 'POST',
      data: this.preparePayload(),
    });

    if (response.isError) {
      this.favoriteLots.loadingStage.error();

      return;
    }

    this.favoriteLots.fillByRawData<LotServer>(
      response.data.items,
      (raw) => {
        const model = LotModel.fromJson(raw, this.rootStore);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.favoriteLots.isInitialLoading.change(false);
    this.favoriteLots.isReplaceLoading.change(false);
    this.favoriteLots.total.change(response.data.total);
    this.favoriteLots.totalPure.change(response.data.total_all);
    this.favoriteLots.setIsAllLoaded(response.data.items.length < this.filters.limit);
    this.favoriteLots.loadingStage.success();
  };

  fetchBidItems = async ({ replace = false }: { replace: boolean }): Promise<void> => {
    if (replace) {
      this.bids.setIsAllLoaded(false);
      this.bids.isReplaceLoading.change(true);
    }

    if (this.bids.loadingStage.isLoading || this.bids.isAllLoaded) {
      return;
    }

    this.bids.loadingStage.loading();

    const response = await api<ProfileLotsResponse<BidServer>>({
      url: apiUrls.bidListProfile,
      method: 'POST',
      data: this.preparePayload(),
    });

    if (response.isError) {
      this.bids.loadingStage.error();

      return;
    }

    this.bids.fillByRawData<BidServer>(
      response.data.items,
      (raw) => {
        const model = BidModel.fromJson(raw, this.rootStore);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.bids.isInitialLoading.change(false);
    this.bids.isReplaceLoading.change(false);
    this.bids.total.change(response.data.total);
    this.bids.totalPure.change(response.data.total_all);
    this.bids.setIsAllLoaded(response.data.items.length < this.filters.limit);
    this.bids.loadingStage.success();
  };

  fetchSubscriptionLotItems = async ({ replace = false }: { replace: boolean }): Promise<void> => {
    if (replace) {
      this.subscriptionLots.setIsAllLoaded(false);
      this.subscriptionLots.isReplaceLoading.change(true);
    }

    if (this.subscriptionLots.loadingStage.isLoading || this.subscriptionLots.isAllLoaded) {
      return;
    }

    this.subscriptionLots.loadingStage.loading();

    const response = await api<ProfileLotsResponse<LotServer>>({
      url: apiUrls.lotListProfile,
      method: 'POST',
      data: this.preparePayload(),
    });

    if (response.isError) {
      this.subscriptionLots.loadingStage.error();

      return;
    }

    this.subscriptionLots.fillByRawData<LotServer>(
      response.data.items,
      (raw) => {
        const model = LotModel.fromJson(raw, this.rootStore);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.subscriptionLots.isInitialLoading.change(false);
    this.subscriptionLots.isReplaceLoading.change(false);
    this.subscriptionLots.total.change(response.data.total);
    this.subscriptionLots.totalPure.change(response.data.total_all);
    this.subscriptionLots.setIsAllLoaded(response.data.items.length < this.filters.limit);
    this.subscriptionLots.loadingStage.success();
  };

  fetchCurrentListItems = async (): Promise<void> => {
    switch (this.filters.params.list_type) {
      case ProfileListType.favorites: {
        this.fetchFavoriteLotItems({ replace: this.filters.offset === 0 });
        break;
      }

      case ProfileListType.bids: {
        this.fetchBidItems({ replace: this.filters.offset === 0 });
        break;
      }

      case ProfileListType.subs: {
        this.fetchSubscriptionLotItems({ replace: this.filters.offset === 0 });
        break;
      }
    }
  };

  destroy(): void {
    super.destroy();
    this.filters.destroy();
  }
}
