import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FavoriteButton } from 'components/favoriteButtons/FavoriteButton';
import SubscribeButton from 'components/subscribeButtons/SubscribeButton';
import { ElementId } from 'config/ids';
import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';

import s from './Footer.module.scss';

type FooterProps = {
  isHovering: boolean;
  isFavorite: boolean;
  handleFavoriteClick: (e: React.MouseEvent) => void;
  isSubscribed: boolean;
  handleSubscribeClick: (e: React.MouseEvent) => void;
  dateAdded: string;
  className?: string;
};

const Footer = ({
  isHovering,
  isFavorite,
  handleFavoriteClick,
  isSubscribed,
  handleSubscribeClick,
  dateAdded,
  className,
}: FooterProps): React.ReactElement<FooterProps> => {
  const { open: showActionButtons, close: hideActionButtons, opened: isActionButtonsVisible } = useToggleState();
  const { onboardingStore } = useRootStore();

  React.useEffect(() => {
    if (isHovering) {
      showActionButtons();
    } else {
      hideActionButtons();
    }
  }, [isHovering]);

  return (
    <div className={cn(s.footer, className)}>
      <Typography.Text className={s['footer__date']}>{dateAdded}</Typography.Text>
      {(isActionButtonsVisible || !onboardingStore.isOnboardingPassedModel.value) && (
        <div className={s.footer__buttons}>
          <SubscribeButton
            id={ElementId.lotCardSubscribeButtonDesktop}
            className={s['footer__subscribe-button']}
            onClick={handleSubscribeClick}
            isSubscribed={isSubscribed}
            variant="default"
          />
          <FavoriteButton
            id={ElementId.lotCardFavoriteButtonDesktop}
            onClick={handleFavoriteClick}
            isFavorite={isFavorite}
            variant="default"
          />
        </div>
      )}
    </div>
  );
};

export default observer(Footer);
