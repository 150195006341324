import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './NavigationItem.module.scss';

type NavigationItemProps = {
  title: string;
  onClick: VoidFunction;
  selected: boolean;
  type?: 'title' | 'subtitle';
};

const NavigationItem: React.FC<NavigationItemProps> = ({ title, onClick, selected, type = 'subtitle' }) => {
  const Item = type === 'subtitle' ? Typography.Text : Typography.Title;

  return (
    <Item className={cn(s.item, s[`item_${type}`], selected && s.item_active)} onClick={onClick} level={4}>
      {title}
    </Item>
  );
};

export default React.memo(NavigationItem);
