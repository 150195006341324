export type TimelineServer = Record<
  string,
  {
    title: string;
    date: string;
  }
>;

export type TimelineStageRaw = {
  title: string;
  date: string;
  phase: TimelinePhase;
};

export interface ITimelineStage {
  title: string;
  date: Date | null;
  phase: TimelinePhase;
}

export enum TimelinePhase {
  bidStartTime = 'bid_start_time',
  bidEndTime = 'bid_end_time',
}
