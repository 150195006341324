import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { SliderIcon } from './SliderIcon';

import s from './SliderHeader.module.scss';

type SliderHeaderProps = {
  isMultipleStages: boolean;
  className?: string;
};

const SliderHeader = ({ isMultipleStages, className }: SliderHeaderProps): React.ReactElement<SliderHeaderProps> => {
  return (
    <div className={cn(s['slider-header'], className)}>
      <Typography.Text type="secondary">Этап аукциона</Typography.Text>
      {isMultipleStages && <SliderIcon />}
    </div>
  );
};

export default SliderHeader;
