import { NavigateFunction } from 'react-router-dom';

import { IRouterStore } from './types';

type RouterStoreParams = {
  navigate: NavigateFunction;
};

export class RouterStore implements IRouterStore {
  readonly navigate: NavigateFunction;

  constructor({ navigate }: RouterStoreParams) {
    this.navigate = navigate;
  }
}
