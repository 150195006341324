import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { useLotPageStore } from 'stores/LotPageStore';
import { scrollToSelector } from 'utils/scrollToSelector';

import NavigationItem from './NavigationItem';

import s from './Navigation.module.scss';

type ActiveSection =
  | ElementId.lotPageCommonDesktop
  | ElementId.lotPagePropertyCharacteristicsDesktop
  | ElementId.lotPageDocsDesktop;

const Navigation: React.FC = () => {
  const { lot } = useLotPageStore();
  const [activeSection, setActiveSection] = React.useState<ActiveSection>(ElementId.lotPageCommonDesktop);

  const handleItemClick = React.useCallback((value: ActiveSection) => {
    setActiveSection(value);
    scrollToSelector({ elSelector: `#${value}` });
  }, []);

  return (
    <Space className={s.navigation} direction="vertical" size={32}>
      <Space direction="vertical" size={4}>
        <NavigationItem
          title="Сведения о лоте"
          onClick={() => handleItemClick(ElementId.lotPageCommonDesktop)}
          selected={activeSection === ElementId.lotPageCommonDesktop}
          type="title"
        />
        <Space direction="vertical" size={0}>
          <NavigationItem
            title="Общие сведения"
            onClick={() => handleItemClick(ElementId.lotPageCommonDesktop)}
            selected={activeSection === ElementId.lotPageCommonDesktop}
          />
          <NavigationItem
            title="Характеристики имущества"
            onClick={() => handleItemClick(ElementId.lotPagePropertyCharacteristicsDesktop)}
            selected={activeSection === ElementId.lotPagePropertyCharacteristicsDesktop}
          />
        </Space>
      </Space>
      {lot.value.documents.length > 0 && (
        <NavigationItem
          title="Документы лота"
          onClick={() => handleItemClick(ElementId.lotPageDocsDesktop)}
          selected={activeSection === ElementId.lotPageDocsDesktop}
          type="title"
        />
      )}
    </Space>
  );
};

export default observer(Navigation);
