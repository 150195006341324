import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { SideFilters as SideFiltersBase } from 'components/SideFilters';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

import { ActionButtons } from './ActionButtons';
import { AuctionType } from './Filters/AuctionType';
import { FormType } from './Filters/FormType';
import { Status } from './Filters/Status';
import { SideFiltersSkeleton } from './SideFiltersSkeleton';

type SideFiltersProps = {
  onFiltersClose: VoidFunction;
  className?: string;
};

const SideFilters = ({ onFiltersClose, className }: SideFiltersProps): React.ReactElement<SideFiltersProps> => {
  const { isListInitialLoading, filters } = useLotListProfileStore();
  const { auctionType, status, formType } = filters;

  if (isListInitialLoading) {
    return <SideFiltersSkeleton />;
  }

  return (
    <SideFiltersBase
      className={className}
      filters={
        <>
          {!auctionType.isEmptyOptions && <AuctionType />}
          {!status.isEmptyOptions && <Status />}
          {!formType.isEmptyOptions && <FormType />}
        </>
      }
      actionButtons={<ActionButtons onClick={onFiltersClose} />}
      onFiltersClose={onFiltersClose}
    />
  );
};

export default observer(SideFilters);
