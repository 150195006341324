import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Slider, { SwiperInstance } from 'components/Slider';
import TimelineStage from 'pages/LotPage/LotTimeline/TimelineStage';
import { useLotPageStore } from 'stores/LotPageStore';

import { SliderButtons } from './SliderButtons';

import s from './Timeline.module.scss';

type TimelineProps = {
  className?: string;
};

const Timeline: React.FC<TimelineProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  const { timelineModel } = lot.value;

  const isMultipleStages = timelineModel.stages.length > 1;

  const [swiperParams, setSwiperParams] = React.useState<Pick<SwiperInstance, 'isBeginning' | 'isEnd'>>({
    isBeginning: false,
    isEnd: false,
  });

  const handleSetSwiperParams = React.useCallback(
    (swiper: SwiperInstance) => {
      setSwiperParams({
        isBeginning: swiper.isBeginning,
        isEnd: swiper.isEnd,
      });
    },
    [setSwiperParams],
  );

  const handleSlideChange = React.useCallback(
    (swiper: SwiperInstance) => {
      const timeline: HTMLElement | null = document.querySelector(`.${s.timeline__bottom}`);

      if (timeline) {
        timeline.style.transform = `translateX(calc(${swiper.activeIndex} * -100vw))`;
      }

      handleSetSwiperParams(swiper);
    },
    [handleSetSwiperParams],
  );

  const slides = timelineModel.stages.map((item) => (
    <div key={item.title} className={s.timeline__item}>
      <Typography.Text className={s['timeline__item-title']}>{item.title}</Typography.Text>
      <TimelineStage key={item.title} model={item} active={timelineModel.activePhase === item.phase} />
    </div>
  ));

  return (
    <div className={cn(s.timeline, className)}>
      <Slider
        onSlideChange={handleSlideChange}
        onSwiper={handleSetSwiperParams}
        slidesPerView={1}
        initialSlide={timelineModel.activePhaseIndex}
        slides={slides}
        className={cn(!isMultipleStages && s.timeline__slider_single)}
      >
        {isMultipleStages && (
          <SliderButtons
            className={s['timeline__slider-buttons']}
            isBeginning={swiperParams.isBeginning}
            isEnd={swiperParams.isEnd}
          />
        )}
      </Slider>
      <div
        className={cn(s.timeline__bottom, !isMultipleStages && s.timeline__bottom_single)}
        style={{ width: `calc(${timelineModel.stages.length} * 100vw - 46px)` }}
      />
    </div>
  );
};

export default observer(Timeline);
