import { ITimelineStage, TimelineStageRaw } from 'entities/timeline';
import { formatDate } from 'utils/getFormattedDate';

export class TimelineStageModel implements ITimelineStage {
  readonly title;
  readonly date;
  readonly phase;

  constructor({ title, date, phase }: ITimelineStage) {
    this.title = title;
    this.date = date;
    this.phase = phase;
  }

  get formattedDate(): string {
    return this.date instanceof Date ? formatDate(this.date) : '—';
  }

  static fromJson(raw: TimelineStageRaw): TimelineStageModel {
    const date = new Date(raw.date);
    return new TimelineStageModel({
      ...raw,
      // @ts-ignore
      date: isNaN(date) ? null : date,
    });
  }
}
