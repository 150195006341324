import * as React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';

export type SwiperInstance = ReturnType<typeof useSwiper>;

import 'swiper/css';

type SliderProps = {
  slides: React.ReactNode[];
  onSlideChange: (swiper: SwiperInstance) => void;
  className?: string;
  onSwiper?: (swiper: SwiperInstance) => void;
} & SwiperOptions;

const Slider: React.FC<React.PropsWithChildren<SliderProps>> = ({
  className,
  children,
  slides,
  onSlideChange,
  ...props
}) => {
  return (
    <Swiper onSlideChange={onSlideChange} className={className} {...props}>
      {slides.map((slide, idx) => (
        <SwiperSlide key={idx}>{slide}</SwiperSlide>
      ))}
      {children}
    </Swiper>
  );
};

export default React.memo(Slider);
