import * as React from 'react';

import { BaseIconProps } from '../commonProps';

type DowngradeIconProps = BaseIconProps;

const DowngradeIcon = (props: DowngradeIconProps): React.ReactElement<DowngradeIconProps> => {
  return (
    <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.9338 23.1262L26.1838 16.9012C26.1588 16.695 25.9057 16.6075 25.7588 16.7544L23.9057 18.6106L15.4275 10.1356C15.2307 9.94186 14.915 9.94186 14.7213 10.1356L11.54 13.3137L2.89316 4.66686C2.84617 4.62034 2.78272 4.59424 2.71659 4.59424C2.65047 4.59424 2.58702 4.62034 2.54003 4.66686L1.13378 6.07936C1.08726 6.12635 1.06116 6.1898 1.06116 6.25593C1.06116 6.32205 1.08726 6.3855 1.13378 6.43249L11.1869 16.4919C11.3807 16.6887 11.6994 16.6887 11.8932 16.4919L15.0744 13.3137L22.14 20.3762L20.2869 22.2294C20.2539 22.2626 20.2308 22.3044 20.2203 22.3501C20.2099 22.3957 20.2125 22.4434 20.2278 22.4877C20.2431 22.532 20.2705 22.5711 20.3069 22.6005C20.3433 22.63 20.3873 22.6486 20.4338 22.6544L26.6588 23.4044C26.8182 23.4262 26.9557 23.2887 26.9338 23.1262Z"
        fill="#FF4D4F"
      />
    </svg>
  );
};

export default DowngradeIcon;
