import { NavigateFunction } from 'react-router-dom';

import { AnalyticsStore, IAnalyticsStore } from 'stores/AnalyticsStore';
import { AuthStore } from 'stores/AuthStore';
import { IOnboardingStore, OnboardingStore } from 'stores/OnboardingStore';
import { UserStore } from 'stores/UserStore';

import { RouterStore } from './RouterStore';
import { IRootStore } from './types';

type RootStoreParams = {
  navigate: NavigateFunction;
};

export class RootStore implements IRootStore {
  readonly routerStore: RouterStore;
  readonly analyticsStore: IAnalyticsStore = new AnalyticsStore();
  readonly userStore: UserStore = new UserStore();
  readonly authStore: AuthStore = new AuthStore({ userStore: this.userStore, analyticsStore: this.analyticsStore });
  readonly onboardingStore: IOnboardingStore;

  constructor({ navigate }: RootStoreParams) {
    this.routerStore = new RouterStore({ navigate });
    this.onboardingStore = new OnboardingStore({ routerStore: this.routerStore });
  }
}
