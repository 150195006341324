import { LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link, useMatch } from 'react-router-dom';

import { routerUrls } from 'config/routes';
import { useRootStore } from 'stores/RootStore';

import ProfileIconButton from '../ProfileIconButton';

import s from './ProfileMenu.module.scss';

type DropdownItemsProps = {
  isProfileRoute: boolean;
  onLogout: VoidFunction;
};

type ProfileMenuProps = {
  className?: string;
};

const getDropdownItems = (props: DropdownItemsProps): MenuProps['items'] => [
  !props.isProfileRoute
    ? {
        key: '1',
        label: <Link to={routerUrls.profile.create()}>Личный кабинет</Link>,
      }
    : null,
  {
    key: '2',
    label: (
      <button className={cn(s['profile-menu__button'], s['profile-menu__button_logout'])} onClick={props.onLogout}>
        <LogoutOutlined />
        <span className={cn(s['profile-menu__logout-text'])}>Выйти</span>
      </button>
    ),
  },
];

const ProfileMenu = ({ className }: ProfileMenuProps): React.ReactElement<ProfileMenuProps> => {
  const { authStore } = useRootStore();
  const isProfileRoute = Boolean(useMatch(routerUrls.profile.mask));

  return (
    <Dropdown
      overlayClassName={cn(s['profile-menu'], className)}
      menu={{
        items: getDropdownItems({
          isProfileRoute,
          onLogout: authStore.logout,
        }),
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      aria-label="Меню профиля"
    >
      <ProfileIconButton color="blue" />
    </Dropdown>
  );
};

export default observer(ProfileMenu);
