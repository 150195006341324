import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { RootStore } from './RootStore';

type RootStoreContextValue = RootStore;

const RootStoreContext = React.createContext<RootStoreContextValue | null>(null);

export const RootStoreContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const rootStore = React.useRef(new RootStore({ navigate }));

  React.useEffect(() => {
    rootStore.current.authStore.authorize();
  }, [rootStore.current]);

  return <RootStoreContext.Provider value={rootStore.current}>{children}</RootStoreContext.Provider>;
};

export const useRootStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context;
};

export const useAuthStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context.authStore;
};
