import { ExportOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { LotSource, lotSourceConfig } from '../../entities/lot';

import s from './DigitalPlatform.module.scss';

type DigitalPlatformProps = {
  source: LotSource;
  size: 'medium' | 'small';
  lotUrl?: string | null;
  className?: string;
};

const DigitalPlatform: React.FC<DigitalPlatformProps> = ({ size, source, lotUrl, className }) => {
  const config = lotSourceConfig[source];

  if (!config) {
    return null;
  }

  const title = config.title;
  const icon = config.icon;

  return (
    <div className={cn(s['digital-platform'], className)}>
      <img src={icon} className={cn(s['digital-platform__image'], s[`digital-platform__image_${source}`])} alt="logo" />
      {lotUrl ? (
        <Button
          className={cn(
            s['digital-platform__title'],
            s['digital-platform__title_link'],
            s[`digital-platform__title_${size}`],
          )}
          type="link"
          href={lotUrl}
          target="_blank"
          rel="noopener noreferrer"
          icon={<ExportOutlined />}
          iconPosition="end"
        >
          {title}
        </Button>
      ) : (
        <Typography.Text
          className={cn(
            s['digital-platform__title'],
            s['digital-platform__title_text'],
            s[`digital-platform__title_${size}`],
          )}
        >
          {title}
        </Typography.Text>
      )}
    </div>
  );
};

export default React.memo(DigitalPlatform);
