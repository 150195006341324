import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { favoriteButtonConfigs } from '../configs';
import { FavoriteButtonBaseProps } from '../types';

import s from './FavoriteButton.module.scss';

type FavoriteButtonProps = FavoriteButtonBaseProps & {
  variant: 'text' | 'default';
};

const FavoriteButton = ({
  isFavorite,
  variant,
  onClick,
  className,
  id,
}: FavoriteButtonProps): React.ReactElement<FavoriteButtonProps> => {
  const Icon = favoriteButtonConfigs[isFavorite ? 'favorite' : 'unfavorite'].icon;
  const buttonText = favoriteButtonConfigs[isFavorite ? 'favorite' : 'unfavorite'].buttonText;

  return (
    <Button
      className={cn(variant === 'text' && s['favorite-button_blue'], className)}
      type={variant}
      icon={<Icon />}
      onClick={onClick}
      id={id}
    >
      {buttonText}
    </Button>
  );
};

export default React.memo(FavoriteButton);
