export enum AnalyticsEvent {
  userLogin = 'login-or-register',
  loginButtonClick = 'login-button-click',
  addToFavorites = 'add-to-favorites',
  removeFromFavorites = 'remove-from-favorites',
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
  lotsListPageViewed = 'lots-list_page-view',
  lotsListClickOnLot = 'lots-list_click-on-lot',
  applyFilters = 'apply-filters',
  telegramFooterLinkClick = 'telegram-footer-link-click',
  bidInvestSend = 'bid-invest-send',
  bidFollowSend = 'bid-follow-send',
}

export type AnalyticsPayload = Record<string, any>;

export type AnalyticsObj = {
  event: AnalyticsEvent;
  payload?: AnalyticsPayload;
};
