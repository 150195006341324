import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { ElementId } from 'config/ids';

import s from './ListControlsBar.module.scss';

type ListControlsBarProps = {
  onFiltersClick: VoidFunction;
  sortComponent: React.ReactNode;
  className?: string;
};

const ListControlsBar = ({
  sortComponent,
  onFiltersClick,
  className,
}: ListControlsBarProps): React.ReactElement<ListControlsBarProps> | null => {
  return (
    <div className={cn(s['list-controls-bar'], className)}>
      {sortComponent}
      <Button
        type="text"
        onClick={onFiltersClick}
        className={s['list-controls-bar__filters-button']}
        id={ElementId.filtersButton}
      >
        Фильтры <FilterOutlined />
      </Button>
    </div>
  );
};

export default ListControlsBar;
