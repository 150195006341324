import cn from 'classnames';
import * as React from 'react';
import { useSwiper } from 'swiper/react';

import { SliderButton } from './SliderButton';

import s from './SliderButtons.module.scss';

type SliderButtonsProps = {
  isBeginning: boolean;
  isEnd: boolean;
  className?: string;
};

const SliderButtons = ({
  isBeginning,
  isEnd,
  className,
}: SliderButtonsProps): React.ReactElement<SliderButtonsProps> => {
  const swiper = useSwiper();

  return (
    <div className={cn(s['slider-buttons'], className)}>
      <SliderButton
        className={s['slider-buttons__button_prev']}
        type="prev"
        disabled={isBeginning}
        onClick={() => swiper.slidePrev()}
      />
      <SliderButton type="next" disabled={isEnd} onClick={() => swiper.slideNext()} />
    </div>
  );
};

export default SliderButtons;
