import { BaseResponseCodeType } from 'types/meta';

const DEFAULT_ERROR_TEXT = 'Произошла неизвестная ошибка. Обновите страницу и попробуйте ещё раз';

export const getErrorMessage = <T extends string>(
  code: BaseResponseCodeType<T> | undefined,
  mapError: Record<T, string>,
): string => {
  if (!code) {
    return DEFAULT_ERROR_TEXT;
  }

  return mapError[code];
};
