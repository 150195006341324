import cn from 'classnames';
import * as React from 'react';

import { Wrapper } from 'components/Wrapper';

import s from './PageContainer.module.scss';

type PageContainerProps = React.PropsWithChildren<{
  className?: string;
  id?: string;
  withoutBottomPadding?: boolean;
}>;

const PageContainer: React.FC<PageContainerProps> = ({ children, className, id, withoutBottomPadding }) => {
  return (
    <Wrapper
      id={id}
      className={cn(s['page-container'], withoutBottomPadding && s['page-container_without-bottom-padding'], className)}
    >
      {children}
    </Wrapper>
  );
};

export default PageContainer;
