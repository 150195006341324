import { Button, Flex, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { SupportModal } from 'components/SupportModal';
import { Wrapper } from 'components/Wrapper';
import TelegramIcon from 'components/icons/TelegramIcon';
import { LINKS } from 'config/links';
import { ProfileListType, routerUrls } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';

import LogoIcon from '../icons/Logo';

import s from './Footer.module.scss';

type FooterProps = {
  className?: string;
};

const Footer: React.FC<FooterProps> = ({ className }) => {
  const { userStore } = useRootStore();
  const currentYear = new Date().getFullYear();
  const { analyticsStore } = useRootStore();

  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();

  const handleTelegramClick = React.useCallback(() => {
    analyticsStore.trackEvent({ event: AnalyticsEvent.telegramFooterLinkClick });
  }, [analyticsStore]);

  return (
    <div className={cn(className, s.footer)}>
      <Wrapper className={cn(s.footer__row, s.footer__links)}>
        <LogoIcon variant="light" />
        <Flex className={s.footer__list}>
          <Link to={routerUrls.lotsList.create()}>
            <Typography.Text className={s.footer__text}>Каталог объектов</Typography.Text>
          </Link>
          {userStore.authorized && (
            <Link to={routerUrls.profile.create(ProfileListType.bids)}>
              <Typography.Text className={s.footer__text}>Личный кабинет</Typography.Text>
            </Link>
          )}
          <Typography.Link className={s.footer__text} onClick={openSupportModal}>
            Служба поддержки
          </Typography.Link>
        </Flex>
        <Button
          className={s['footer__button-icon']}
          type="link"
          href={LINKS.telegram}
          target="_blank"
          icon={<TelegramIcon />}
          onClick={handleTelegramClick}
          aria-label="Telegram"
        />
      </Wrapper>
      <Wrapper className={cn(s.footer__row, s.footer__agreements)}>
        <Typography.Text className={s.footer__agreement}>
          © Copyright {currentYear}. Все права защищены.
        </Typography.Text>
        <Typography.Link target="_blank" className={s.footer__agreement} href={LINKS.politics}>
          Политика обработки персональных данных
        </Typography.Link>
      </Wrapper>
      <SupportModal opened={openedSupportModal} close={closeSupportModal} />
    </div>
  );
};

export default observer(Footer);
