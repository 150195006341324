import { Dropdown, MenuProps, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { SortDirection, SortOption } from 'entities/lotFilters';

import { DROPDOWN_ITEMS, DropdownSortOptions } from './configs';

import s from './DropdownSort.module.scss';

type DropdownSortProps = {
  onClick: (value: SortOption, direction?: SortDirection) => void;
  className?: string;
};

const DropdownSort = ({ onClick, className }: DropdownSortProps): React.ReactElement<DropdownSortProps> | null => {
  const [activeDropDownItem, setActiveDropDownItem] = React.useState<DropdownSortOptions>(DropdownSortOptions.newFirst);

  const handleDropItemClick = React.useCallback(
    (value: SortOption, direction: SortDirection, optionTitle: DropdownSortOptions) => {
      onClick(value, direction);
      setActiveDropDownItem(optionTitle);
    },
    [setActiveDropDownItem, onClick],
  );

  const items = React.useMemo<MenuProps['items']>(() => {
    return DROPDOWN_ITEMS.map((item) => ({
      key: item.key,
      onClick: () => {
        handleDropItemClick(item.sortOption, item.direction, item.key);
      },
      className: cn(s['dropdown__item'], activeDropDownItem === item.key && s['dropdown__item_active']),
      label: <span>{item.key}</span>,
    }));
  }, [activeDropDownItem]);

  return (
    <div id={ElementId.sortControlsMobile} className={className}>
      <Dropdown
        menu={{
          items,
          rootClassName: s.dropdown,
        }}
      >
        <Typography.Text className={s.dropdown__selected}>{activeDropDownItem}</Typography.Text>
      </Dropdown>
    </div>
  );
};

export default React.memo(DropdownSort);
