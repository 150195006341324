import { ProfileListType, RouteSegment } from './constants';

export const routerUrls = {
  lotsList: {
    mask: `/${RouteSegment.lots}`,
    create: () => `/${RouteSegment.lots}`,
  },
  lotDetail: {
    mask: `/${RouteSegment.lots}/:id`,
    create: (id: number) => `/${RouteSegment.lots}/${id}`,
  },
  profile: {
    mask: `/${RouteSegment.profile}`,
    create: (listType = ProfileListType.favorites) => `/${RouteSegment.profile}?list_type=${listType}`,
  },
};
