import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TimelineStageModel } from 'models/TimelineStageModel';

import s from './TimelineStage.module.scss';

type TimelineStageProps = {
  model: TimelineStageModel;
  active: boolean;
};

const TimelineStage: React.FC<TimelineStageProps> = ({ active, model }) => {
  return (
    <Typography.Text
      className={cn(s['timeline-stage'], s['timeline-stage__text'], active && s['timeline-stage__text_active'])}
    >
      {!active && <div className={s['timeline-stage__status']} />}
      {model.formattedDate}
    </Typography.Text>
  );
};

export default React.memo(TimelineStage);
