import { Button, Space, Typography } from 'antd';
import * as React from 'react';

import { ProfileListType, routerUrls } from 'config/routes';

import s from './Card.module.scss';

type CardProps = {
  title: string;
  description: string;
  isApplicationSend?: boolean;
  isLoading?: boolean;
  onClick?: VoidFunction;
};

const Card: React.FC<CardProps> = ({ title, description, isApplicationSend, onClick, isLoading }) => {
  return (
    <Space direction="vertical" className={s.card}>
      <div>
        <Typography.Title className={s.card__title} level={3}>
          {title}
        </Typography.Title>
        <Typography.Text className={s.card__description} type="secondary">
          {description}
        </Typography.Text>
      </div>
      {!isApplicationSend ? (
        <Button className={s.card__button} onClick={onClick} type="primary" size="large" loading={isLoading}>
          Оставить заявку
        </Button>
      ) : (
        <Button
          className={s.card__button}
          href={routerUrls.profile.create(ProfileListType.bids)}
          size="large"
          loading={isLoading}
        >
          Мои заявки
        </Button>
      )}
    </Space>
  );
};

export default React.memo(Card);
