import React from 'react';
import { createRoot } from 'react-dom/client';

import 'scripts/init';

import './styles/reset.css';
import './styles/global.scss';

import { Root } from 'pages/Root';

createRoot(document.getElementById('root') as HTMLElement).render(<Root />);
