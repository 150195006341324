import { Virtualizer, useVirtualizer } from '@tanstack/react-virtual';
import * as React from 'react';

export type InfiniteScrollConfig = {
  /** Длина списка */
  length: number;
  hasNextPage: boolean;
  /** Предполагаемая высота элемента */
  estimateItemSize: number;
  /** Количество элементов, не попадающих в окно списка, которые надо отрендерить */
  overscan?: number;
  /** Вертикальный отступ контейнера */
  verticalPadding?: number;
  /** Функция для загрузки новых элементов */
  fetchItems: VoidFunction;
  isLoading: boolean;
  /** Список перевернут (скролл вверх) */
  isReversed?: boolean;
};

export type InfiniteScrollValue = {
  listRef: React.RefObject<HTMLDivElement>;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
};

export const useInfiniteScroll = ({
  overscan = 0,
  length,
  hasNextPage,
  isLoading,
  estimateItemSize,
  fetchItems,
}: InfiniteScrollConfig): InfiniteScrollValue => {
  const listRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    // Если загружены еще не все элементы, добавляем один элемент для лоадера
    count: hasNextPage ? length + 1 : length,
    getScrollElement: () => listRef.current,
    estimateSize: () => estimateItemSize,
    overscan,
  });

  const items = rowVirtualizer.getVirtualItems();

  React.useEffect(() => {
    // Выбираем последний виртуальный элемент
    const lastItem = items[items.length - 1];

    // Если нет последнего элемента (например список пуст), прерываем функцию
    if (!lastItem) {
      return;
    }

    const lastMessageIndex = length - 1;

    /**
     * Если индекс последнего виртуального элемента больше, чем индекс последнего элемента списка, значит
     * пользователь проскроллил список до компонента лоадера и надо запросить новые элементы
     */
    if (hasNextPage && lastItem.index >= lastMessageIndex && !isLoading) {
      fetchItems();
    }
  }, [length, items, items.length]);

  return {
    listRef,
    rowVirtualizer,
  };
};
