import { reaction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { LotServer, LotsResponse } from 'entities/lot';
import { ListModel } from 'models/ListModel';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { LotModel } from 'models/LotModel';
import { ValueModel } from 'models/ValueModel';
import { LocalStore } from 'stores/LocalStore';
import { IRootStore } from 'stores/RootStore';
import { AppEvent } from 'types/appEvent';
import { BaseResponse } from 'types/meta';

import { apiStore } from '../../utils/apiRequest';

import { LotListCatalogFiltersModel } from './LotListCatalogFiltersModel';

type LotListStoreParams = {
  queryParams: URLSearchParams;
  rootStore: IRootStore;
};

export class LotListStore extends LocalStore {
  readonly lots: ListModel<LotModel, number> = new ListModel<LotModel, number>();
  readonly filters: LotListCatalogFiltersModel;
  readonly draftFavoriteLot: ValueModel<LotModel['id'] | null> = new ValueModel<LotModel['id'] | null>(null);
  readonly draftSubscribedLot: ValueModel<LotModel['id'] | null> = new ValueModel<LotModel['id'] | null>(null);
  readonly addToFavoritesLoadingStage: LoadingStageModel = new LoadingStageModel();
  readonly addToSubscriptionsLoadingStage: LoadingStageModel = new LoadingStageModel();
  private readonly rootStore: IRootStore;

  private lotListRequest = apiStore.createRequest<LotsResponse>({ method: 'POST', url: apiUrls.lotList });

  constructor({ queryParams, rootStore }: LotListStoreParams) {
    super();

    this.filters = new LotListCatalogFiltersModel({ params: queryParams, routerStore: rootStore.routerStore });
    this.filters.init();
    this.rootStore = rootStore;

    this.subscribe(AppEvent.lotDraftAddToFavorites, ({ lotId }) => {
      this.draftFavoriteLot.change(lotId);
      this.rootStore.authStore.popupController.open();
    });

    this.subscribe(AppEvent.lotDraftSubscribe, ({ lotId }) => {
      this.draftSubscribedLot.change(lotId);

      if (!this.rootStore.userStore.authorized) {
        this.rootStore.authStore.popupController.open();
        return;
      }

      if (!this.rootStore.userStore.user?.hasEmail) {
        this.rootStore.userStore.emailPopupController.open();
      }
    });

    this.addReactions([
      reaction(
        () => this.filters.offset,
        () => {
          this.fetchLotItems({ replace: this.filters.offset === 0 });
        },
      ),
      reaction(
        () => this.filters.params,
        () => this.fetchLotItems({ replace: this.filters.offset === 0 }),
      ),
      reaction(
        () => this.rootStore.userStore.authorized,
        async (isAuthorized: boolean) => {
          if (isAuthorized) {
            await this.checkHasDraftFavorites();
          }

          this.filters.resetOffset();
          this.fetchLotItems({ replace: this.filters.offset === 0 });
        },
      ),
      reaction(
        () => this.rootStore.userStore.authorized,
        async (isAuthorized: boolean) => {
          if (isAuthorized) {
            await this.checkHasDraftSubscriptions();
          }

          this.filters.resetOffset();
          this.fetchLotItems({ replace: this.filters.offset === 0 });
        },
      ),
      reaction(
        () => this.rootStore.userStore.user?.hasEmail,
        async (hasEmail: boolean | undefined) => {
          if (hasEmail) {
            await this.checkHasDraftSubscriptions();
          }

          this.filters.resetOffset();
          this.fetchLotItems({ replace: this.filters.offset === 0 });
        },
      ),
    ]);
  }

  private async checkHasDraftFavorites(): Promise<void> {
    if (this.draftFavoriteLot.value === null || this.addToFavoritesLoadingStage.isLoading) {
      return;
    }

    this.addToFavoritesLoadingStage.loading();

    const lotResponse = await LotModel.fromApi(this.draftFavoriteLot.value, this.rootStore);

    if (!lotResponse.isError) {
      await lotResponse.data.addToFavorites();
      this.draftFavoriteLot.change(null);
      this.addToFavoritesLoadingStage.success();

      return;
    }

    this.addToFavoritesLoadingStage.error();
  }

  private async checkHasDraftSubscriptions(): Promise<void> {
    if (this.draftSubscribedLot.value === null || this.addToSubscriptionsLoadingStage.isLoading) {
      return;
    }

    if (!this.rootStore.userStore.user?.hasEmail) {
      this.rootStore.userStore.emailPopupController.open();

      return;
    }

    this.addToSubscriptionsLoadingStage.loading();

    const lotResponse = await LotModel.fromApi(this.draftSubscribedLot.value, this.rootStore);

    if (!lotResponse.isError) {
      await lotResponse.data.addToSubscriptions();
      this.draftSubscribedLot.change(null);
      this.addToSubscriptionsLoadingStage.success();

      return;
    }

    this.addToSubscriptionsLoadingStage.error();
  }

  fetchLotItems = async ({
    replace = false,
  }: {
    replace: boolean;
  }): Promise<BaseResponse<ListModel<LotModel, number>>> => {
    if (replace) {
      this.lots.setIsAllLoaded(false);
      this.lots.isReplaceLoading.change(true);
    }

    if (this.lots.isAllLoaded || this.draftSubscribedLot.value !== null || this.draftFavoriteLot.value !== null) {
      return { isError: true };
    }

    if (this.lots.loadingStage.isLoading) {
      this.lotListRequest.cancel();
    }

    this.lots.loadingStage.loading();

    const response = await this.lotListRequest.call({
      data: {
        params: {
          limit: this.filters.limit,
          offset: this.filters.offset,
          search: this.filters.params.search,
          order: this.filters.params.order,
          order_direction: this.filters.params.order_direction,
        },
        filters: {
          object_type: this.filters.params.object_type,
          aggregated_type: this.filters.isAllAggregationType ? null : this.filters.params.aggregated_type,
          auction_type: this.filters.params.auction_type,
          square: {
            from: this.filters.params.area_from,
            to: this.filters.params.area_to,
          },
          price: {
            from: this.filters.params.price_from,
            to: this.filters.params.price_to,
          },
          form_type: this.filters.params.form_type,
          auction_organizer: this.filters.params.organizer,
          status: this.filters.params.status,
          region: this.filters.params.region,
          city: this.filters.params.city,
        },
      },
    });

    if (response.isError) {
      this.lots.loadingStage.error();

      return { isError: true };
    }

    this.lots.fillByRawData<LotServer>(
      response.data.items,
      (raw) => {
        const model = LotModel.fromJson(raw, this.rootStore);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.lots.isInitialLoading.change(false);
    this.lots.isReplaceLoading.change(false);
    this.lots.total.change(response.data.total);
    this.lots.setIsAllLoaded(response.data.items.length < this.filters.limit);
    this.lots.loadingStage.success();

    return { isError: false, data: this.lots };
  };

  destroy(): void {
    super.destroy();
    this.filters.destroy();
  }
}
