import { Skeleton, Tabs, TabsProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './Tabbar.module.scss';

type TabbarProps = {
  selected: string | number | null;
  tabsOptions: TabsProps['items'];
  onChange: (value: string) => void;
  size?: TabsProps['size'];
  id?: string;
  className?: string;
};

const Tabbar = ({
  selected,
  tabsOptions,
  onChange,
  size = 'middle',
  id,
  className,
}: TabbarProps): React.ReactElement<TabbarProps> | null => {
  if (selected === null) {
    return <Skeleton.Input active size="large" className={cn(s['tabbar'], s['tabbar__skeleton'], className)} />;
  }

  if (tabsOptions?.length === 0) {
    return null;
  }

  return (
    <Tabs
      className={cn(s['tabbar'], className)}
      activeKey={String(selected)}
      items={tabsOptions}
      onChange={onChange}
      size={size}
      popupClassName={s['tabbar__popup']}
      id={id}
    />
  );
};

export default Tabbar;
