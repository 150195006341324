import cn from 'classnames';
import * as React from 'react';

import { SortItem } from 'components/SortItem';
import { ElementId } from 'config/ids';
import { SortDirection, SortOption, SortOptionItem } from 'entities/lotFilters';

import s from './Sort.module.scss';

type SortProps = {
  options: SortOptionItem[];
  selected: SortOption;
  onClick: (value: SortOption) => void;
  sortDirection: SortDirection;
  className?: string;
};

const Sort = ({
  options,
  selected,
  onClick,
  sortDirection,
  className,
}: SortProps): React.ReactElement<SortProps> | null => {
  return (
    <div className={cn(s.sort, className)} id={ElementId.sortControlsDesktop}>
      {options.map((option) => (
        <SortItem
          id={option.id}
          key={option.id}
          label={option.title}
          onClick={onClick}
          withSortDirection={option.withSortDirection}
          sortDirection={sortDirection}
          active={selected === option.id}
        />
      ))}
    </div>
  );
};

export default React.memo(Sort);
