import * as React from 'react';

import { CtxStoreValue } from 'types/context';
import { BaseResponse, LoadingStage } from 'types/meta';

type LocalState<T> =
  | {
      stage: LoadingStage.notStarted | LoadingStage.error | LoadingStage.loading;
    }
  | { stage: LoadingStage.success; ctx: CtxStoreValue<T> };

const useAsyncLocalStore = <T>(initialValueCreator: () => Promise<BaseResponse<T>>): LocalState<T> => {
  const [storeCtx, setStoreCtx] = React.useState<
    | {
        stage: LoadingStage.notStarted | LoadingStage.error | LoadingStage.loading;
      }
    | { stage: LoadingStage.success; ctx: CtxStoreValue<T> }
  >({ stage: LoadingStage.notStarted });

  React.useEffect(() => {
    const init = async () => {
      setStoreCtx({
        stage: LoadingStage.loading,
      });
      const response = await initialValueCreator();

      if (response.isError) {
        setStoreCtx({
          stage: LoadingStage.error,
        });
      } else {
        setStoreCtx({
          stage: LoadingStage.success,
          ctx: {
            // @ts-ignore
            store: response.data,
          },
        });
      }
    };

    init();
  }, []);

  return storeCtx;
};

export default useAsyncLocalStore;
