import { Button, Input, Modal, Space, Typography } from 'antd';
import cn from 'classnames';
import { E164Number } from 'libphonenumber-js/core';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import PhoneInput from 'react-phone-number-input';

import { LINKS } from 'config/links';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { AuthStep } from 'stores/AuthStore';
import { useRootStore } from 'stores/RootStore';
import { pluralizePreselected } from 'utils/pluralizer';

import CountrySelectComponent from './CountrySelectComponent';

import s from './AuthPopup.module.scss';
import 'react-phone-number-input/style.css';

const AuthPopup: React.FC = () => {
  const { authStore } = useRootStore();

  const {
    phone,
    code,
    register,
    authStep,
    checkCode,
    secondsCounter,
    getCode,
    checkCodeStage,
    registerStage,
    changePhoneNumber,
    popupController,
  } = authStore;

  const isInitialStep = authStep.value === AuthStep.initial;
  const isGetCodeTimeOut = secondsCounter.value === 0;

  const handlePhoneChange = React.useCallback(
    (value?: E164Number | undefined) => {
      if (value) {
        phone.change(value);
      }
    },
    [phone],
  );

  const handleCodeChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      code.change(e.target.value);
    },
    [code],
  );

  const handleFormSubmit = React.useCallback(() => {
    return isInitialStep ? register() : checkCode();
  }, [isInitialStep, register, checkCode]);

  useKeyboardEvent('Enter', handleFormSubmit, popupController.value);

  return (
    <Modal
      classNames={{ body: s.popup__body }}
      className={s.popup}
      open={popupController.value}
      footer={false}
      closeIcon={false}
      onCancel={popupController.close}
      centered
    >
      <Space className={s.popup__content} direction="vertical" size={24}>
        <Typography.Title className={cn(s.popup__title, s.popup__text)} level={2}>
          Войти или зарегистрироваться
        </Typography.Title>
        <Space className={s.popup__content} direction="vertical" size={16}>
          <Typography.Text type="secondary">Введите ваш номер телефона, чтобы получить код из смс</Typography.Text>
          <Space className={s['popup__input']} direction="vertical" size={0}>
            <PhoneInput
              className={cn(s['popup__input'], s.popup__phone, phone.error && s.popup__input_error)}
              onChange={handlePhoneChange}
              placeholder="(___)__-__-__"
              value={phone.value || undefined}
              defaultCountry="RU"
              disabled={!isInitialStep}
              countrySelectComponent={CountrySelectComponent}
            />
            <Typography.Text type="danger">{phone.error}</Typography.Text>
          </Space>
          {!isInitialStep && (
            <Typography.Link className={s['popup__link']} onClick={changePhoneNumber}>
              Изменить номер
            </Typography.Link>
          )}
          {isInitialStep && (
            <Button type="primary" onClick={register} loading={registerStage.isLoading} disabled={phone.value === null}>
              Получить код
            </Button>
          )}
          {!isInitialStep && (
            <Space className={s.popup__confirm} direction="vertical" size={16}>
              <Space className={s['popup__input']} direction="vertical" size={8}>
                <Typography.Text className={s.popup__text}>Код из смс</Typography.Text>
                <Space className={s['popup__input']} direction="vertical" size={0}>
                  <Input
                    className={s['popup__input']}
                    onChange={handleCodeChange}
                    value={code.value}
                    type="number"
                    placeholder="Ввести код"
                    status={code.error ? 'error' : undefined}
                  />
                  <Typography.Text type="danger">{code.error}</Typography.Text>
                </Space>
              </Space>
              {!isGetCodeTimeOut && (
                <Typography.Text className={s.popup__text}>
                  Запросить код повторно можно через {pluralizePreselected(secondsCounter.value, 'секунда', 'venative')}
                </Typography.Text>
              )}
              {isGetCodeTimeOut && (
                <Typography.Link className={s['popup__link']} onClick={getCode}>
                  Получить код повторно
                </Typography.Link>
              )}
              <Button
                onClick={checkCode}
                type="primary"
                loading={checkCodeStage.isLoading}
                disabled={code.value === null}
              >
                Подтвердить телефон
              </Button>
            </Space>
          )}
        </Space>
        <Typography.Text type="secondary">
          Нажимая на кнопку «Получить код» я соглашаюсь с{' '}
          <Typography.Link className={s['popup__link']} href={LINKS.politics} target="_blank">
            политикой обработки персональных данных
          </Typography.Link>
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default observer(AuthPopup);
