import { Input } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useLotListStore } from 'stores/LotListCatalogStore/context';

import s from './SearchBar.module.scss';

type SearchBarProps = {
  className?: string;
};

const SearchBar = ({ className }: SearchBarProps): React.ReactElement<SearchBarProps> => {
  const { filters } = useLotListStore();

  const handleSearchInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filters.search.change(e.target.value);

      if (e.target.value === '') {
        filters.setSearchValue(e.target.value);
      }
    },
    [filters.search.change],
  );

  return (
    <Input.Search
      className={cn(s['search-bar'], className)}
      value={filters.search.value}
      onChange={handleSearchInput}
      onSearch={filters.setSearchValue}
      placeholder="Поиск по объектам"
      size="large"
      allowClear
    />
  );
};

export default observer(SearchBar);
