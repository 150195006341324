import { LotServer } from 'entities/lot';

export enum BidType {
  // Заявка по инвест привлекательности
  invest = 'invest',
  // Заявка на сопровождение сделки
  follow = 'follow',
}

export enum BidStatus {
  new = 'new',
  inProgress = 'in_progress',
  finished = 'finished',
}

export type BidServer = {
  bid_id: number;
  type: BidType;
  status: BidStatus;
  lot: LotServer;
};
