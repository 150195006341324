import * as React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { App } from 'pages/App';
import { LotPage } from 'pages/LotPage';
import { LotsListPage } from 'pages/LotsListPage';
import { Profile } from 'pages/Profile';
import CommonError from 'pages/errors/CommonError';

import { routerUrls } from './routerUrls';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <CommonError />,
    children: [
      {
        path: routerUrls.lotsList.mask,
        element: <LotsListPage />,
      },
      {
        path: routerUrls.lotDetail.mask,
        element: <LotPage />,
      },
      {
        path: routerUrls.profile.mask,
        element: <Profile />,
      },
      {
        index: true,
        element: <Navigate to={routerUrls.lotsList.create()} replace />,
      },
      {
        path: '*',
        element: <Navigate to={routerUrls.lotsList.create()} replace />,
      },
    ],
  },
]);

export default router;
