import * as React from 'react';
import { Outlet } from 'react-router-dom';

import AuthPopup from 'components/AuthPopup';
import EmailPopup from 'components/EmailPopup';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Onboarding } from 'components/Onboarding';
import { useScrollToTop } from 'hooks/useScrollTop';
import { WidthProvider } from 'hooks/useWidth';
import { RootStoreContextProvider } from 'stores/RootStore';

import s from './App.module.scss';

const App: React.FC = () => {
  useScrollToTop();

  return (
    <RootStoreContextProvider>
      <WidthProvider>
        <div className={s.app__container}>
          <Header />
          <Outlet />
          <Footer />
        </div>
        <AuthPopup />
        <EmailPopup />
        <Onboarding />
      </WidthProvider>
    </RootStoreContextProvider>
  );
};

export default App;
