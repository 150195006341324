import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { useLocalStore } from 'hooks/useLocalStore';
import { useRootStore } from 'stores/RootStore';

import { LotListProfileStore } from './LotListProfileStore';

type LotListProfileStoreContextValue = LotListProfileStore;

const LotListProfileStoreContext = React.createContext<LotListProfileStoreContextValue | null>(null);

export const LotListProfileStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [queryParams] = useSearchParams();
  const rootStore = useRootStore();

  const store = useLocalStore(() => new LotListProfileStore({ queryParams, rootStore }));

  React.useEffect(() => {
    store.fetchCurrentListItems();
  }, []);

  useKeyboardEvent('Enter', store.filters.setSideFilters);

  return <LotListProfileStoreContext.Provider value={store}>{children}</LotListProfileStoreContext.Provider>;
};

export const useLotListProfileStore = () => {
  const context = React.useContext(LotListProfileStoreContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
