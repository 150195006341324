import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ListWithFilters as ListWithFiltersBase } from 'components/ListWithFilters';
import { useWidth } from 'hooks/useWidth';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

import { LotsList } from '../LotsList';
import { SideFilters } from '../SideFilters';
import { Sort } from '../Sort';

type ListWithFiltersProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const ListWithFilters = ({
  isFiltersCollapsed,
  className,
}: ListWithFiltersProps): React.ReactElement<ListWithFiltersProps> => {
  const store = useLotListStore();
  const { isDesktop } = useWidth();

  return (
    <ListWithFiltersBase
      className={className}
      SideFilters={SideFilters}
      sortComponent={<Sort />}
      listComponent={<LotsList />}
      isListEmpty={store.lots.items.length === 0 && isDesktop}
      isFiltersCollapsed={isFiltersCollapsed}
    />
  );
};

export default observer(ListWithFilters);
