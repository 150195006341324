import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ActionButtons as ActionButtonsBase } from 'components/SideFilters/ActionButtons';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

type ActionButtonsProps = {
  className?: string;
  onClick?: VoidFunction;
};

const ActionButtons = ({ className, onClick }: ActionButtonsProps): React.ReactElement<ActionButtonsProps> => {
  const { filters } = useLotListProfileStore();

  const handleSetSideFilters = React.useCallback(() => {
    onClick?.();
    filters.setSideFilters();
  }, []);

  return (
    <ActionButtonsBase
      className={className}
      isEmptySideFilters={filters.isEmptySideFilters}
      onClearSideFilters={filters.clearSideFilters}
      onSetSideFilters={handleSetSideFilters}
      isLoading={filters.loadingStage.isLoading}
    />
  );
};

export default observer(ActionButtons);
