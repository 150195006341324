import cn from 'classnames';
import * as React from 'react';

import { ButtonIcon } from 'components/ButtonIcon';
import { ButtonIconSize } from 'components/ButtonIcon/types';
import { PageTitle } from 'components/PageTitle';
import { ArrowIcon } from 'components/icons/ArrowIcon';
import { useWidth } from 'hooks/useWidth';

import s from './PageListTitle.module.scss';

type PageListTitleProps = React.PropsWithChildren<{
  opened: boolean;
  toggle: VoidFunction;
  className?: string;
}>;

const PageListTitle = ({
  opened,
  toggle,
  className,
  children,
}: PageListTitleProps): React.ReactElement<PageListTitleProps> => {
  const { isPhone } = useWidth();

  return (
    <div className={cn(s['page-list-title'], className)}>
      <PageTitle>{children}</PageTitle>
      {isPhone && (
        <ButtonIcon buttonSize={ButtonIconSize.small} onClick={toggle}>
          <ArrowIcon direction={opened ? 'bottom' : 'top'} />
        </ButtonIcon>
      )}
    </div>
  );
};

export default PageListTitle;
