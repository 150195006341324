import { Space, Typography } from 'antd';
import * as React from 'react';

import s from './SectionBlock.module.scss';

type SectionBlockProps = {
  title: string;
  description?: string;
};

const SectionBlock: React.FC<SectionBlockProps> = ({ title, description }) => {
  return (
    <Space direction="vertical" size={4}>
      <Typography.Title className={s.block__title} type="secondary" level={3}>
        {title}
      </Typography.Title>
      {description && <Typography.Text className={s.block__description}>{description}</Typography.Text>}
    </Space>
  );
};

export default React.memo(SectionBlock);
