import { type PluralizeFormatFunction, type PluralizeVariants, pluralizeTemplate } from '@kts-front/utils';

export const format: PluralizeFormatFunction = ({ count, value }) => `${count} ${value}`;

export const formatWithoutCount: PluralizeFormatFunction = ({ value }) => value;

export type WordCase = 'nominative' | 'venative';

export type PluralizableWord = 'просмотр' | 'объект' | 'значение' | 'секунда' | 'найден' | 'отложено';

const PLURALIZABLE_WORDS: Record<PluralizableWord, Record<WordCase, PluralizeVariants>> = {
  просмотр: {
    nominative: {
      one: 'просмотр',
      two: 'просмотра',
      five: 'просмотров',
    },
    venative: {
      one: 'просмотр',
      two: 'просмотра',
      five: 'просмотров',
    },
  },
  объект: {
    nominative: {
      one: 'объект',
      two: 'объекта',
      five: 'объектов',
    },
    venative: {
      one: 'объект',
      two: 'объекта',
      five: 'объектов',
    },
  },
  значение: {
    nominative: {
      one: 'значение',
      two: 'значения',
      five: 'значений',
    },
    venative: {
      one: 'значение',
      two: 'значения',
      five: 'значений',
    },
  },
  секунда: {
    nominative: {
      one: 'секунда',
      two: 'секунды',
      five: 'секунд',
    },
    venative: {
      one: 'секунду',
      two: 'секунды',
      five: 'секунд',
    },
  },
  найден: {
    nominative: {
      one: 'найден',
      two: 'найдено',
      five: 'найдено',
    },
    venative: {
      one: 'найденного',
      two: 'найденных',
      five: 'найденных',
    },
  },
  отложено: {
    nominative: {
      one: 'отложен',
      two: 'отложено',
      five: 'отложено',
    },
    venative: {
      one: 'отложенного',
      two: 'отложенных',
      five: 'отложенных',
    },
  },
};

export const pluralizePreselected = (n: number, word: PluralizableWord, wordCase: WordCase = 'nominative') =>
  pluralizeTemplate({ count: n, variants: PLURALIZABLE_WORDS[word][wordCase], format });

export const pluralizePreselectedWithoutCount = (
  n: number,
  word: PluralizableWord,
  wordCase: WordCase = 'nominative',
) => pluralizeTemplate({ count: n, variants: PLURALIZABLE_WORDS[word][wordCase], format: formatWithoutCount });
