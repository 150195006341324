import { Skeleton } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './LotCardSkeleton.module.scss';

type LotCardSkeletonProps = {
  className?: string;
};

const LotCardSkeleton = ({ className }: LotCardSkeletonProps): React.ReactElement<LotCardSkeletonProps> => {
  return (
    <div className={cn(s['lot-card-skeleton'], className)}>
      <div className={s['lot-card-skeleton__layout']}>
        <Skeleton.Avatar active shape="square" />
        <div className={s['lot-card-skeleton__main-content']}>
          <Skeleton.Input active size="small" className={s['lot-card-skeleton__tags-row']} />
          <Skeleton active paragraph={{ rows: 6 }} title={{ width: 250 }} />
          <Skeleton.Input active size="small" className={s['lot-card-skeleton__date']} />
        </div>
      </div>
    </div>
  );
};

export default LotCardSkeleton;
