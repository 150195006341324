import { computed, makeObservable } from 'mobx';

import { ValueModel } from 'models/ValueModel';
import { Validator } from 'types/validator';

export class FilterModelRange {
  readonly valueMin: ValueModel<number | null>;
  readonly valueMax: ValueModel<number | null>;
  readonly from: ValueModel<number | null> = new ValueModel<number | null>(null);
  readonly to: ValueModel<number | null> = new ValueModel<number | null>(null);

  constructor(valueMin: number | null, valueMax: number | null, validators: Validator<number | null>[] = []) {
    this.valueMin = new ValueModel<number | null>(valueMin, validators);
    this.valueMax = new ValueModel<number | null>(valueMax, validators);

    makeObservable<this>(this, {
      isEmpty: computed,
    });
  }

  reset = (): void => {
    this.valueMin.change(null);
    this.valueMax.change(null);
  };

  get isEmpty(): boolean {
    return this.valueMin.value === null && this.valueMax.value === null;
  }

  static createMinValidator: (from: number, error?: string) => Validator<number> =
    (from: number, error = '') =>
    (value: number) => {
      return value < from ? error : null;
    };

  static createMaxValidator: (to: number, error?: string) => Validator<number> =
    (to: number, error = '') =>
    (value: number) => {
      return value > to ? error : null;
    };
}
