import cn from 'classnames';
import * as React from 'react';

import s from './Wrapper.module.scss';

type WrapperProps = React.PropsWithChildren<{
  id?: string;
  className?: string;
}>;

const Wrapper = ({ children, className, id }: WrapperProps): React.ReactElement<WrapperProps> => {
  return (
    <div id={id} className={cn(s.wrapper, className)}>
      {children}
    </div>
  );
};

export default Wrapper;
