import { BidServer, BidType, BidStatus } from 'entities/bid';
import { IRootStore } from 'stores/RootStore';

import { LotModel } from './LotModel';

type BidModelParams = {
  id: number;
  type: BidType;
  lot: LotModel;
  status: BidStatus;
};

export class BidModel {
  readonly id;
  readonly type;
  readonly lot;
  readonly status;

  constructor({ id, type, lot, status }: BidModelParams) {
    this.id = id;
    this.type = type;
    this.lot = lot;
    this.status = status;
  }

  static fromJson(raw: BidServer, rootStore: IRootStore): BidModel {
    return new BidModel({
      id: raw.bid_id,
      type: raw.type,
      status: raw.status,
      lot: LotModel.fromJson(raw.lot, rootStore),
    });
  }
}
