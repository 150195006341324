import cn from 'classnames';
import * as React from 'react';

import Tags from 'components/Tags';
import { Status } from 'entities/lot';
import { LotModel } from 'models/LotModel';

import { ImageGallery } from '../ImageGallery';
import Layout from '../Layout';

import MobileContent from './MobileContent';

import s from './MobileLotCard.module.scss';

type MobileLotCardProps = {
  item: LotModel;
  style?: React.CSSProperties;
  additionalTags?: Status[];
  className?: string;
};

const MobileLotCard = ({
  item,
  className,
  style,
  additionalTags,
}: MobileLotCardProps): React.ReactElement<MobileLotCardProps> => {
  return (
    <Layout item={item} style={style} className={cn(s.card, className)}>
      <ImageGallery images={item.images} />
      <Tags
        className={s.card__tags}
        status={item.status}
        auctionFormat={item.auctionFormat}
        additionalTags={additionalTags}
      />
      <MobileContent lot={item} />
    </Layout>
  );
};

export default React.memo(MobileLotCard);
