import { FilePdfOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { LotModel } from 'models/LotModel';
import { getFileName } from 'utils/getFileName';

import Section from '../Section';

import s from './DocsSection.module.scss';

type DocsSectionProps = {
  lot: LotModel;
  id: ElementId;
};

const DocsSection: React.FC<DocsSectionProps> = ({ lot, id }) => {
  if (lot.documents.length < 1) {
    return null;
  }

  return (
    <Section title="Документы лота" id={id}>
      {lot.documents.map((document) => (
        <div key={document.url} className={s.section__document}>
          <Typography.Text type="secondary">{getFileName(document.name)}</Typography.Text>
          <Typography.Link className={s.section__link} href={document.url}>
            <FilePdfOutlined className={s['section__document-icon']} />
            {document.name}
          </Typography.Link>
        </div>
      ))}
    </Section>
  );
};

export default React.memo(DocsSection);
