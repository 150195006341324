import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cn from 'classnames';
import * as React from 'react';

import { ButtonIcon } from 'components/ButtonIcon';

import s from './SliderButton.module.scss';

type SliderButtonProps = {
  type: 'prev' | 'next';
  disabled: boolean;
  onClick: () => void;
  className?: string;
};

const SliderButton = ({
  type,
  disabled,
  className,
  onClick,
}: SliderButtonProps): React.ReactElement<SliderButtonProps> => {
  const Icon = type === 'prev' ? LeftOutlined : RightOutlined;

  return <ButtonIcon Icon={Icon} className={cn(s['slider-button'], className)} disabled={disabled} onClick={onClick} />;
};

export default SliderButton;
