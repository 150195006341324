import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { subscribeButtonConfigs } from '../configs';
import { BaseSubscribeButtonProps } from '../types';

import s from './SubscribeButton.module.scss';

type SubscribeButtonProps = BaseSubscribeButtonProps & {
  variant: 'text' | 'default';
};

const SubscribeButton = ({
  isSubscribed,
  variant,
  onClick,
  className,
  id,
}: SubscribeButtonProps): React.ReactElement<SubscribeButtonProps> => {
  const Icon = subscribeButtonConfigs[isSubscribed ? 'subscribed' : 'unsubscribed'].icon;
  const buttonText = subscribeButtonConfigs[isSubscribed ? 'subscribed' : 'unsubscribed'].buttonText;

  return (
    <Button
      className={cn(variant === 'text' && s['subscribe-button_blue'], className)}
      type={variant}
      icon={<Icon />}
      onClick={onClick}
      id={id}
    >
      {buttonText}
    </Button>
  );
};

export default React.memo(SubscribeButton);
