import { ItemType, MenuItemType } from 'antd/es/menu/interface';

import { RouteSegment } from 'config/routes/constants';
import { routerUrls } from 'config/routes/routerUrls';

type MenuItem = {
  // Паттерн для мэтчинга через matchPath
  pattern: string;
  // URL страницы для навигации при клике на элемент меню
  url: string;
  title: string;
};

export const MENU_ITEMS: MenuItem[] = [
  {
    pattern: `/${RouteSegment.lots}/*`,
    url: routerUrls.lotsList.create(),
    title: 'Каталог объектов',
  },
];

// Конфиг для компонента Menu
export const headerMenuItems: ItemType<MenuItemType>[] = MENU_ITEMS.map((menuItem) => ({
  key: menuItem.url,
  label: menuItem.title,
}));
