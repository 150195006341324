import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Status } from 'entities/lot';
import useToggleState from 'hooks/useToggleState';
import { LotModel } from 'models/LotModel';

import { ImageGallery } from '../ImageGallery';
import Layout from '../Layout';
import { Meta } from '../Meta';

import { Footer } from './Footer';
import { Header } from './Header';
import { Main } from './Main';

import s from './LotCard.module.scss';

export type LotCardProps = {
  item: LotModel;
  additionalTags?: Status[];
  style?: React.CSSProperties;
  className?: string;
};

const LotCard = ({ item, className, style, additionalTags }: LotCardProps): React.ReactElement<LotCardProps> => {
  const { opened: isHovering, open, close } = useToggleState();
  const handleFavoriteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      item.startToggleFavorite();
    },
    [item],
  );

  const handleSubscribeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      item.startToggleSubscription();
    },
    [item],
  );

  return (
    <Layout item={item} style={style} className={className} handleMouseEnter={open} handleMouseLeave={close}>
      <ImageGallery images={item.images} />
      <div className={s['lot-card__main-content']}>
        <Header
          status={item.status}
          auctionFormat={item.auctionFormat}
          auctionType={item.auctionType}
          objectType={item.objectType}
          priceMin={item.priceMinFormatted}
          totalArea={item.totalAreaFormatted}
          viewsCount={item.viewsCount}
          additionalTags={additionalTags}
          isDowngradeAuction={item.isDowngradeAuction}
        />
        <Main address={item.lotCardAddress} source={item.source} />
        <Meta floor={item.floor} totalFloors={item.amountOfFloors} entranceCount={item.entrance} />
        <Footer
          dateAdded={item.dateAdded}
          isHovering={isHovering}
          isFavorite={item.isInFavorite.value}
          handleFavoriteClick={handleFavoriteClick}
          isSubscribed={item.isInSubscribed.value}
          handleSubscribeClick={handleSubscribeClick}
        />
      </div>
    </Layout>
  );
};

export default observer(LotCard);
