export enum ElementId {
  root = 'root',
  lotPage = 'lot-page',
  lotPageCommonDesktop = 'lot-page-common-section-desktop',
  lotPageCommonMobile = 'lot-page-common-section-mobile',
  lotPagePropertyCharacteristicsDesktop = 'lot-page-property-characteristics-desktop',
  lotPagePropertyCharacteristicsMobile = 'lot-page-property-characteristics-mobile',
  lotPageDocsDesktop = 'lot-page-docs-desktop',
  lotPageDocsMobile = 'lot-page-docs-mobile',
  lotPageMainInfo = 'lot-page-main-info',
  lotPageImageCarouselDesktop = 'lot-page-image-carousel-desktop',
  lotPageImageCarouselMobile = 'lot-page-image-carousel-mobile',
  loginButton = 'login-button',
  filters = 'filters',
  filtersButton = 'filters-button',
  sortControlsDesktop = 'sort-controls-desktop',
  sortControlsMobile = 'sort-controls-mobile',
  lotsList = 'lots-list',
  lotCardFavoriteButtonDesktop = 'lot-card-favorite-button-desktop',
  lotCardSubscribeButtonDesktop = 'lot-card-subscribe-button-desktop',
  lotCardFavoriteButtonMobile = 'lot-card-favorite-button-mobile',
  lotCardSubscribeButtonMobile = 'lot-card-subscribe-button-mobile',
}
