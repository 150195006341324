import { useEffect, useRef } from 'react';

import { type ILocalStore } from 'types/ILocalStore';

export const useLocalStore = <S extends ILocalStore>(creator: () => S, dependencies: React.DependencyList = []): S => {
  const store = useRef<S | null>(null);
  const outdated = useRef<boolean>(false);

  if (store.current === null || outdated.current) {
    store.current = creator();
    outdated.current = false;
  }

  useEffect(() => {
    // Нужно для корректной работы в Strict mode
    if (outdated.current) {
      store.current = creator();
      outdated.current = false;
    }

    return () => {
      store.current?.destroy();
      outdated.current = true;
    };
  }, dependencies);

  return store.current;
};
