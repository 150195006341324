import { action, makeObservable } from 'mobx';

import { Validator } from 'types/validator';

import { ValueModel } from './ValueModel';

export interface IToggleModel {
  toggle: VoidFunction;
  close: VoidFunction;
  open: VoidFunction;
}

export class ToggleModel extends ValueModel<boolean> implements IToggleModel {
  constructor(value: boolean = false, validators: Validator<boolean>[] = []) {
    super(value, validators);

    makeObservable(this, {
      toggle: action.bound,
      close: action.bound,
      open: action.bound,
    });
  }

  toggle() {
    this.change(!this.value);
  }

  close() {
    this.change(false);
  }

  open() {
    this.change(true);
  }
}
