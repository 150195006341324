import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { pluralizePreselected } from 'utils/pluralizer';

import s from './TotalItemsCount.module.scss';

type TotalItemsCountProps = {
  prefix: string;
  itemsCount: number | null;
  className?: string;
};

const TotalItemsCount = ({
  prefix,
  itemsCount,
  className,
}: TotalItemsCountProps): React.ReactElement<TotalItemsCountProps> => {
  return (
    <Typography.Text className={cn(s['total-items-count'], className)}>
      {prefix} {pluralizePreselected(itemsCount ?? 0, 'объект')}
    </Typography.Text>
  );
};

export default TotalItemsCount;
