import { Divider, Space, Tag, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { DigitalPlatform } from 'components/DigitalPlatform';
import { ElementId } from 'config/ids';
import { useLotPageStore } from 'stores/LotPageStore';
import { pluralizePreselected } from 'utils/pluralizer';

import Header from './Header';
import InfoBlock from './InfoBlock';

import s from './MainInfo.module.scss';

const MainInfo: React.FC = () => {
  const { lot } = useLotPageStore();
  const {
    auctionType,
    priceMinFormatted,
    priceStepOfTotalFormatted,
    source,
    timelineModel,
    formattedBidEndTime,
    city,
    viewsCount,
    priceStepFormatted,
    priceDecreaseStepFormatted,
    cutoffPriceFormatted,
    lotUrl,
    isDowngradeAuction,
  } = lot.value;

  return (
    <div className={s['main-info']} id={ElementId.lotPageMainInfo}>
      <Header className={s['main-info__header']} />
      <Space direction="vertical" size="middle" className={s['main-info__body']}>
        <InfoBlock title="Вид торгов" text={auctionType} />
        <InfoBlock
          title="Начальная цена"
          text={
            <Space align="start" size={10}>
              <span>{priceMinFormatted}</span>
              <Tag bordered={false} className={s['main-info__price-tag']} color="blue">
                С учетом НДС
              </Tag>
            </Space>
          }
        />
        {priceStepFormatted !== null && (
          <InfoBlock
            title="Шаг аукциона"
            text={
              priceStepOfTotalFormatted === null
                ? priceStepFormatted
                : `${priceStepFormatted} (${priceStepOfTotalFormatted} %)`
            }
          />
        )}
        <InfoBlock
          title="Электронная площадка"
          text={
            <DigitalPlatform
              source={source}
              className={s['main-info__digital-platform']}
              lotUrl={lotUrl}
              size="small"
            />
          }
        />
        {isDowngradeAuction && priceDecreaseStepFormatted !== null && (
          <InfoBlock title="Шаг понижения цены" text={priceDecreaseStepFormatted} />
        )}
        {isDowngradeAuction && cutoffPriceFormatted !== null && (
          <InfoBlock title="Цена отсечения" text={cutoffPriceFormatted} />
        )}
        <InfoBlock title="Субъект местонахождения имущества" text={city} />
        {timelineModel.bidEndTimeStage && <InfoBlock title="Окончание приема заявок" text={formattedBidEndTime} />}
        <Divider className={s['main-info__divider']} />
        <Typography.Text type="secondary">{pluralizePreselected(viewsCount, 'просмотр')}</Typography.Text>
      </Space>
    </div>
  );
};

export default observer(MainInfo);
