import * as React from 'react';

import ImgCarousel, { CarouselImageSize } from 'components/ImgCarousel';
import { ImageModel } from 'models/ImageModel';

type ImageGalleryProps = {
  images: ImageModel[];
  className?: string;
};

const ImageGallery = ({ images, className }: ImageGalleryProps): React.ReactElement<ImageGalleryProps> => {
  return (
    <ImgCarousel
      className={className}
      images={images}
      imgSize={CarouselImageSize.s}
      withoutThumbnails
      withoutFullscreen
    />
  );
};

export default React.memo(ImageGallery);
