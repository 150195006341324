import { Status } from 'entities/lot';

import { BidStatus, BidType } from './server';

export const bidStatusConfig: Record<BidStatus, Status> = {
  [BidStatus.new]: {
    statusTitle: 'Новая',
    statusTagColor: 'geekblue',
  },
  [BidStatus.inProgress]: {
    statusTitle: 'В работе',
    statusTagColor: 'geekblue',
  },
  [BidStatus.finished]: {
    statusTitle: 'Завершено',
    statusTagColor: 'green',
  },
};

export const bidTypeConfig: Record<BidType, Status> = {
  [BidType.invest]: {
    statusTitle: 'Инвест привлекательность',
    statusTagColor: 'default',
  },
  [BidType.follow]: {
    statusTitle: 'Сопровождение сделки',
    statusTagColor: 'default',
  },
};
