import * as React from 'react';

import { ButtonIcon } from 'components/ButtonIcon';
import { Tooltip } from 'components/Tooltip';

import { favoriteButtonConfigs } from '../configs';
import { FavoriteButtonMiniProps } from '../types';

const FavoriteButtonMini: React.FC<FavoriteButtonMiniProps> = ({
  className,
  isFavorite,
  onClick,
  withoutTooltip,
  id,
}) => {
  const Icon = favoriteButtonConfigs[isFavorite ? 'favorite' : 'unfavorite'].icon;
  const tooltipText = favoriteButtonConfigs[isFavorite ? 'favorite' : 'unfavorite'].tooltipText;

  const button = <ButtonIcon Icon={Icon} id={id} className={className} onClick={onClick} aria-label={tooltipText} />;

  if (withoutTooltip) {
    return button;
  }

  return (
    <Tooltip placement="topRight" title={tooltipText}>
      {button}
    </Tooltip>
  );
};

export default React.memo(FavoriteButtonMini);
