import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { LotStatus } from 'entities/lot';
import { useLotPageStore } from 'stores/LotPageStore';

import TimelineStage from './TimelineStage';

import s from './LotTimeline.module.scss';

type LotTimelineProps = {
  className?: string;
};

const LotTimeline: React.FC<LotTimelineProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  const { status, timelineModel } = lot.value;

  if (status === LotStatus.canceled) {
    return null;
  }

  return (
    <div className={cn(className, s.timeline)}>
      <div className={s.timeline__row}>
        {timelineModel.stages.map((item) => (
          <Typography.Text key={item.title} className={cn(s.timeline__item, s['timeline__item-title'])}>
            {item.title}
          </Typography.Text>
        ))}
      </div>
      <div className={cn(s.timeline__row, s.timeline__row_bottom)}>
        {timelineModel.stages.map((item) => (
          <TimelineStage key={item.title} model={item} active={timelineModel.activePhase === item.phase} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(LotTimeline);
