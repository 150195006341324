import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cn from 'classnames';
import * as React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import defaultImage from 'assets/defaultImage.png';
import { DETAIL_PREVIEW_SIZE, ImageModel, LIST_PREVIEW_SIZE } from 'models/ImageModel';

import FullScreenButton from './FullScreenButton';

import s from './ImgCarousel.module.scss';

import 'react-image-gallery/styles/css/image-gallery.css';

const DEFAULT_IMAGE_ALT = 'preview';

export enum CarouselImageSize {
  s = LIST_PREVIEW_SIZE,
  l = DETAIL_PREVIEW_SIZE,
}

// Фактический размер картинки при рендере
const previewSizeMap: Record<CarouselImageSize, number> = {
  [CarouselImageSize.s]: 200,
  [CarouselImageSize.l]: 385,
};

type ImgCarouselProps = {
  handleFullscreen?: (isFullscreen: boolean) => void;
  images: ImageModel[];
  className?: string;
  id?: string;
  withoutThumbnails?: boolean;
  withoutFullscreen?: boolean;
  imgSize?: CarouselImageSize;
};

const ImgCarousel: React.FC<ImgCarouselProps> = ({
  images,
  handleFullscreen,
  className,
  id,
  withoutThumbnails = false,
  withoutFullscreen = false,
  imgSize = CarouselImageSize.s,
}) => {
  const galleryRef = React.useRef<ImageGallery | null>(null);

  const convertedImages = React.useMemo<ReactImageGalleryItem[]>(() => {
    if (images.length === 0) {
      return [
        {
          original: defaultImage,
          thumbnail: defaultImage,
          fullScreen: defaultImage,
          originalHeight: previewSizeMap[imgSize],
          originalAlt: DEFAULT_IMAGE_ALT,
          thumbnailAlt: DEFAULT_IMAGE_ALT,
        },
      ];
    }

    return images.map((image) => ({
      original: imgSize === CarouselImageSize.s ? image.listPreviewUrl : image.detailPreviewUrl,
      thumbnail: imgSize === CarouselImageSize.s ? image.listPreviewUrl : image.detailPreviewUrl,
      fullscreen: image.fullUrl,
      originalHeight: previewSizeMap[imgSize],
      originalAlt: image.alt,
      thumbnailAlt: image.alt,
    }));
  }, [images]);

  const isDefaultImage = convertedImages[0].thumbnailAlt === DEFAULT_IMAGE_ALT;

  const handleClick = React.useCallback(() => {
    if (galleryRef.current && !isDefaultImage && !withoutFullscreen) {
      galleryRef.current.fullScreen();
    }
  }, [galleryRef, convertedImages]);

  // Предотвращение всплытия клика, чтобы не происходил переход в деталку лота
  const handleGalleryArrowClick = React.useCallback(
    (e: React.MouseEvent, callback: React.MouseEventHandler<Element>) => {
      e.preventDefault();
      e.stopPropagation();
      callback(e);
    },
    [],
  );

  return (
    <div id={id}>
      <ImageGallery
        ref={galleryRef}
        additionalClass={cn(className, s.carousel, withoutThumbnails && s.carousel__thumbnail_disabled)}
        items={convertedImages}
        infinite={false}
        showPlayButton={false}
        onScreenChange={handleFullscreen}
        onClick={handleClick}
        showFullscreenButton={!isDefaultImage}
        renderLeftNav={(onClick, disabled) => (
          <div
            onClick={(e) => handleGalleryArrowClick(e, onClick)}
            className={cn(
              s['carousel__navigation-container'],
              s['carousel__navigation-container_left'],
              disabled && s['carousel__navigation-container_disabled'],
            )}
          >
            <LeftOutlined className={cn(s.carousel__navigation)} />
          </div>
        )}
        renderRightNav={(onClick, disabled) => (
          <div
            className={cn(
              s['carousel__navigation-container'],
              s['carousel__navigation-container_right'],
              disabled && s['carousel__navigation-container_disabled'],
            )}
            onClick={(e) => handleGalleryArrowClick(e, onClick)}
          >
            <RightOutlined className={cn(s.carousel__navigation)} />
          </div>
        )}
        renderFullscreenButton={(onClick, isFullscreen) =>
          !withoutFullscreen && <FullScreenButton onClick={onClick} isFullscreen={isFullscreen} />
        }
      />
    </div>
  );
};

export default React.memo(ImgCarousel);
