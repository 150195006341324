import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import React from 'react';
import { Link } from 'react-router-dom';

export const breadcrumbItemsRender = (
  route: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>,
  _: any,
  routes: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
): React.ReactNode => {
  const isLast = routes.indexOf(route) === routes.length - 1;
  return isLast || !route.href ? <span>{route.title}</span> : <Link to={route.href}>{route.title}</Link>;
};
