import { reaction, runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { AnalyticsEvent } from 'entities/analytics';
import { BidType } from 'entities/bid';
import { LotServer } from 'entities/lot';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { LotModel } from 'models/LotModel';
import { ValueModel } from 'models/ValueModel';
import { LocalStore } from 'stores/LocalStore';
import { IRootStore } from 'stores/RootStore';
import { AppEvent } from 'types/appEvent';
import { BaseResponse } from 'types/meta';
import { api } from 'utils/api';

export class LotPageStore extends LocalStore {
  readonly lot: ValueModel<LotModel>;
  readonly draftFavoriteLot: ValueModel<LotModel['id'] | null> = new ValueModel<LotModel['id'] | null>(null);
  readonly draftSubscribedLot: ValueModel<LotModel['id'] | null> = new ValueModel<LotModel['id'] | null>(null);
  readonly applicationInvestLoadStage: LoadingStageModel = new LoadingStageModel();
  readonly applicationFollowLoadStage: LoadingStageModel = new LoadingStageModel();
  readonly updateLoadStage: LoadingStageModel = new LoadingStageModel();
  readonly applicationType: ValueModel<BidType | null> = new ValueModel<BidType | null>(null);
  private readonly rootStore: IRootStore;

  constructor(lot: LotModel, rootStore: IRootStore) {
    super();

    this.lot = new ValueModel(lot);
    this.rootStore = rootStore;

    this.subscribe(AppEvent.lotDraftAddToFavorites, ({ lotId }) => {
      this.draftFavoriteLot.change(lotId);
      this.rootStore.authStore.popupController.open();
    });

    this.subscribe(AppEvent.lotDraftSubscribe, ({ lotId }) => {
      this.draftSubscribedLot.change(lotId);

      if (!this.rootStore.userStore.authorized) {
        this.rootStore.authStore.popupController.open();
        return;
      }

      if (!this.rootStore.userStore.user?.hasEmail) {
        this.rootStore.userStore.emailPopupController.open();
      }
    });

    this.addReactions([
      reaction(
        () => this.rootStore.userStore.authorized,
        (isAuthorized: boolean) => {
          if (isAuthorized) {
            this.checkFavoritesAfterAuth();
          }
        },
      ),
      reaction(
        () => this.rootStore.userStore.authorized,
        (isAuthorized: boolean) => {
          if (isAuthorized) {
            this.checkHasDraftSubscriptions();
          }
        },
      ),
      reaction(
        () => this.rootStore.userStore.user?.hasEmail,
        () => this.checkHasDraftSubscriptions(),
      ),
      reaction(
        () => this.rootStore.userStore.authorized,
        async (isAuthorized: boolean) => {
          await this.updateLotModel(this.lot.value.id);

          if (isAuthorized) {
            this.sendApplication();
          }
        },
      ),
    ]);
  }

  private async checkFavoritesAfterAuth(): Promise<void> {
    if (this.draftFavoriteLot.value === null) {
      return;
    }

    const lotResponse = await LotModel.fromApi(this.lot.value.id, this.rootStore);

    if (!lotResponse.isError) {
      await lotResponse.data.addToFavorites();
      this.lot.change(lotResponse.data);
      this.draftFavoriteLot.change(null);
    }
  }

  private async checkHasDraftSubscriptions(): Promise<void> {
    if (this.draftSubscribedLot.value === null) {
      return;
    }

    if (!this.rootStore.userStore.user?.hasEmail) {
      this.rootStore.userStore.emailPopupController.open();

      return;
    }

    const lotResponse = await LotModel.fromApi(this.draftSubscribedLot.value, this.rootStore);

    if (!lotResponse.isError) {
      await lotResponse.data.addToSubscriptions();
      this.lot.change(lotResponse.data);
      this.draftSubscribedLot.change(null);
    }
  }

  sendInvestApplication = () => {
    this.applicationType.change(BidType.invest);
    this.sendApplication();
  };

  sendFollowApplication = () => {
    this.applicationType.change(BidType.follow);
    this.sendApplication();
  };

  private sendApplication = async (): Promise<BaseResponse> => {
    const isFollowApplication = this.applicationType.value === BidType.follow;
    const isInvestApplication = this.applicationType.value === BidType.invest;

    const applicationLoadStage = isFollowApplication
      ? this.applicationFollowLoadStage
      : this.applicationInvestLoadStage;

    if (isFollowApplication && this.lot.value.isFollowBidExistedValue) {
      return { isError: true };
    }

    if (isInvestApplication && this.lot.value.isInvestBidExistedValue) {
      return { isError: true };
    }

    if (this.applicationType.value === BidType.follow && this.lot.value.isFollowBidExistedValue) {
      return { isError: true };
    }

    if (this.applicationType.value === BidType.invest && this.lot.value.isInvestBidExistedValue) {
      return { isError: true };
    }

    if (applicationLoadStage.isLoading || this.applicationType.value === null) {
      return { isError: true };
    }

    if (!this.rootStore.userStore.authorized) {
      this.rootStore.authStore.popupController.open();

      return { isError: true };
    }

    applicationLoadStage.loading();

    const response = await api({
      url: apiUrls.bid(this.lot.value.id),
      method: 'POST',
      data: {
        type: this.applicationType.value,
      },
    });

    if (response.isError) {
      applicationLoadStage.error();

      runInAction(() => {
        this.applicationType.change(null);
      });

      return {
        isError: response.isError,
      };
    }

    runInAction(() => {
      applicationLoadStage.success();
      if (this.applicationType.value) {
        this.lot.value.setIsBidExistedValue(this.applicationType.value);

        this.rootStore.analyticsStore.trackEvent({
          event:
            this.applicationType.value === BidType.invest ? AnalyticsEvent.bidInvestSend : AnalyticsEvent.bidFollowSend,
          payload: { lot_name: this.lot.value.title },
        });
      }
      this.applicationType.change(null);
    });

    return { isError: false };
  };

  updateLotModel = async (id: number): Promise<BaseResponse> => {
    if (this.updateLoadStage.isLoading) {
      return { isError: true };
    }

    // Обновление лота после разлогина
    if (!this.rootStore.userStore.authorized) {
      this.lot.value.reset();

      return { isError: false };
    }

    this.updateLoadStage.loading();

    const response = await api<LotServer>({
      url: apiUrls.lot(id),
    });

    if (response.isError) {
      this.updateLoadStage.error();

      return {
        isError: response.isError,
      };
    }

    runInAction(() => {
      this.updateLoadStage.success();
      this.lot.change(LotModel.fromJson(response.data, this.rootStore));
    });

    return {
      isError: false,
    };
  };

  static async initializeAsync(id: number, rootStore: IRootStore): Promise<BaseResponse<LotPageStore>> {
    const response = await api<LotServer>({
      url: apiUrls.lot(id),
    });

    if (response.isError) {
      return {
        isError: response.isError,
      };
    }

    return {
      data: new LotPageStore(LotModel.fromJson(response.data, rootStore), rootStore),
      isError: false,
    };
  }
}
