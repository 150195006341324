import { Space, Typography } from 'antd';
import * as React from 'react';

import s from './InfoBlock.module.scss';

type InfoBlockProps = {
  title: string;
  text: React.ReactNode;
};

const InfoBlock: React.FC<InfoBlockProps> = ({ text, title }) => {
  return (
    <Space className={s['info-block']}>
      <Typography.Title className={s['info-block__title']} type="secondary" level={5}>
        {title}
      </Typography.Title>
      <Typography.Text className={s['info-block__description']}>{text}</Typography.Text>
    </Space>
  );
};

export default React.memo(InfoBlock);
