import { Skeleton } from 'antd';
import * as React from 'react';

import s from './SideFiltersSkeleton.module.scss';

type SideFiltersSkeletonProps = {
  className?: string;
};

const SideFiltersSkeleton = ({ className }: SideFiltersSkeletonProps): React.ReactElement<SideFiltersSkeletonProps> => {
  return (
    <div className={className}>
      <Skeleton.Input active size="large" className={s['skeleton__section-title']} />
      <Skeleton.Input active size="small" className={s.skeleton__title} />
      <Skeleton.Input active size="large" className={s.skeleton__row} />
      <Skeleton.Input active size="small" className={s.skeleton__title} />
      <Skeleton.Input active size="large" className={s.skeleton__row} />
      <Skeleton.Input active size="small" className={s.skeleton__title} />
      <Skeleton.Input active size="large" className={s.skeleton__row} />
    </div>
  );
};

export default SideFiltersSkeleton;
