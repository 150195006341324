import cn from 'classnames';
import * as React from 'react';

import { ProfileIcon } from 'components/icons/ProfileIcon';

import s from './ProfileIconButton.module.scss';

type ProfileIconButtonProps = {
  onClick?: VoidFunction;
  className?: string;
  color?: 'grey' | 'blue';
};

const ProfileIconButton: React.FC<ProfileIconButtonProps> = ({ onClick, className, color = 'grey' }) => {
  return (
    <button onClick={onClick} className={cn(className, s['button'])} aria-label="Войти">
      <ProfileIcon className={cn(s['button__icon'], s[`button__icon_${color}`])} />
    </button>
  );
};

export default React.memo(ProfileIconButton);
