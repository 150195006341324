import { Collapse, CollapseProps, Space } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Wrapper } from 'components/Wrapper';
import { ElementId } from 'config/ids';
import { useLotPageStore } from 'stores/LotPageStore';
import { useRootStore } from 'stores/RootStore';

import CommonSection from './CommonSection';
import DocsSection from './DocsSection';
import Navigation from './Navigation';
import PropertySection from './PropertySection';

import s from './CommonInfo.module.scss';

type CommonInfoProps = {
  className?: string;
};

const CommonInfo: React.FC<CommonInfoProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  const { onboardingStore } = useRootStore();

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Сведения о лоте',
      children: (
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <CommonSection lot={lot.value} id={ElementId.lotPageCommonMobile} />
          <PropertySection lot={lot.value} id={ElementId.lotPagePropertyCharacteristicsMobile} />
        </Space>
      ),
    },
  ];

  if (lot.value.documents.length > 0) {
    items.push({
      key: '2',
      label: 'Документы лота',
      children: <DocsSection lot={lot.value} id={ElementId.lotPageDocsMobile} />,
    });
  }

  // Для онбординга необходимо раскрыть секцию со сведениями о лоте
  const defaultActiveKey = !onboardingStore.isOnboardingPassedModel.value ? ['1'] : undefined;

  return (
    <Wrapper id={ElementId.lotPage} className={cn(className, s.info)}>
      <Navigation />
      <Space className={s.info__content} direction="vertical" size={24}>
        <CommonSection lot={lot.value} id={ElementId.lotPageCommonDesktop} />
        <PropertySection lot={lot.value} id={ElementId.lotPagePropertyCharacteristicsDesktop} />
        <DocsSection lot={lot.value} id={ElementId.lotPageDocsDesktop} />
      </Space>
      <Collapse
        className={s['info__mobile-content']}
        defaultActiveKey={defaultActiveKey}
        items={items}
        bordered={false}
      />
    </Wrapper>
  );
};

export default observer(CommonInfo);
