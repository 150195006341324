import * as React from 'react';

import { ButtonIcon } from 'components/ButtonIcon';
import { Tooltip } from 'components/Tooltip';

import { subscribeButtonConfigs } from '../configs';
import { MiniSubscribeButtonProps } from '../types';

const SubscribeButtonMini: React.FC<MiniSubscribeButtonProps> = ({
  className,
  onClick,
  isSubscribed,
  withoutTooltip = false,
  id,
}) => {
  const tooltipTitle = subscribeButtonConfigs[isSubscribed ? 'subscribed' : 'unsubscribed'].tooltipText;
  const Icon = subscribeButtonConfigs[isSubscribed ? 'subscribed' : 'unsubscribed'].icon;

  const button = <ButtonIcon Icon={Icon} id={id} className={className} onClick={onClick} aria-label={tooltipTitle} />;

  if (withoutTooltip) {
    return button;
  }

  return (
    <Tooltip placement="topRight" title={tooltipTitle} trigger={['hover']}>
      {button}
    </Tooltip>
  );
};

export default React.memo(SubscribeButtonMini);
