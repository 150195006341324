import { useEffect, useState } from 'react';

export const useObserveHeight = (element: Element, shouldObserve: boolean) => {
  const [height, setHeight] = useState(element.clientHeight);

  useEffect(() => {
    if (!shouldObserve) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => setHeight(entries[0].target.clientHeight));

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [shouldObserve]);

  return height;
};
