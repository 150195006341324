import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { ButtonIconSize } from './types';

import s from './ButtonIcon.module.scss';

type ButtonIconProps = {
  Icon?: React.ComponentType;
  iconRendered?: React.ReactNode;
  id?: string;
  buttonSize?: ButtonIconSize;
  className?: string;
} & React.ComponentProps<typeof Button>;

const ButtonIcon = ({
  Icon,
  children,
  id,
  buttonSize = ButtonIconSize.medium,
  className,
  ...props
}: React.PropsWithChildren<ButtonIconProps>): React.ReactElement<ButtonIconProps> => {
  return (
    <Button id={id} className={cn(className, s['button-icon'], s[`button-icon_size-${buttonSize}`])} {...props}>
      {Icon && <Icon />}
      {children}
    </Button>
  );
};

export default React.memo(ButtonIcon);
