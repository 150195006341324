import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import * as React from 'react';

import { Header } from 'components/Header';
import { routerUrls } from 'config/routes';
import { RootStoreContextProvider } from 'stores/RootStore';

import BaseErrorLayout from '../BaseErrorLayout';

import s from './CommonError.module.scss';

const CommonError: React.FC = () => {
  return (
    <RootStoreContextProvider>
      <Header />
      <BaseErrorLayout>
        <CloseCircleFilled style={{ fontSize: '70px', color: '#FF4D4F' }} />
        <Typography.Title className={s.error__title} level={2}>
          Произошла неизвестная ошибка
        </Typography.Title>
        <Button href={routerUrls.lotsList.create()} type="primary">
          Вернуться в каталог
        </Button>
      </BaseErrorLayout>
    </RootStoreContextProvider>
  );
};

export default React.memo(CommonError);
