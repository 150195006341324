import { Spin } from 'antd';
import * as React from 'react';

import s from './PageLoader.module.scss';

const PageLoader: React.FC = () => {
  return <Spin className={s.loader} />;
};

export default React.memo(PageLoader);
