import { EyeOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './ViewsCount.module.scss';

type ViewsCountProps = {
  viewsCount: number;
  className?: string;
};

const ViewsCount = ({ viewsCount, className }: ViewsCountProps): React.ReactElement<ViewsCountProps> => {
  return (
    <div className={cn(s['views-count'], className)}>
      <EyeOutlined className={s['views-count__icon']} />
      <Typography.Text className={s['views-count__number']}>{viewsCount}</Typography.Text>
    </div>
  );
};

export default React.memo(ViewsCount);
