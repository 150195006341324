import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type OrganizerProps = {
  className?: string;
};

const Organizer = ({ className }: OrganizerProps): React.ReactElement<OrganizerProps> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput className={className} title="Организатор">
      <MultiSelect
        options={filters.organizer.options}
        selectedOptions={filters.organizer.selectedOptions}
        value={filters.organizer.selectedValue.value}
        onChange={filters.organizer.toggleValue}
        onSearch={filters.organizer.handleSearch}
        searchValue={filters.organizer.searchValue.value}
        isOptionsLoading={filters.loadingStage.isLoading}
      />
    </BaseInput>
  );
};

export default observer(Organizer);
