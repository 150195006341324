import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type RegionProps = {
  className?: string;
};

const Region = ({ className }: RegionProps): React.ReactElement<RegionProps> => {
  const { filters } = useLotListStore();

  const onRegionChange = React.useCallback((value: number) => {
    filters.setRegionValue(value);
  }, []);

  return (
    <BaseInput className={className} title="Регион">
      <MultiSelect
        options={filters.region.options}
        selectedOptions={filters.region.selectedOptions}
        value={filters.region.selectedValue.value}
        onChange={onRegionChange}
        onSearch={filters.region.handleSearch}
        searchValue={filters.region.searchValue.value}
        isOptionsLoading={filters.loadingRegionsStage.isLoading}
      />
    </BaseInput>
  );
};

export default observer(Region);
