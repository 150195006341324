import cn from 'classnames';
import * as React from 'react';

import { PageContainer } from 'components/PageContainer';

import s from './BaseErrorLayout.module.scss';

type BaseErrorLayoutProps = {
  className?: string;
};

const BaseErrorLayout: React.FC<React.PropsWithChildren<BaseErrorLayoutProps>> = ({ children, className }) => {
  return <PageContainer className={cn(className, s['error-layout'])}>{children}</PageContainer>;
};

export default BaseErrorLayout;
