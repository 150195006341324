import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { AreaText } from 'components/AreaText';
import FavoriteButtonMini from 'components/favoriteButtons/FavoriteButtonMini';
import SubscribeButtonMini from 'components/subscribeButtons/SubscribeButtonMini';
import { useWidth } from 'hooks/useWidth';
import LotPageTags from 'pages/LotPage/LotPageTags';
import { useLotPageStore } from 'stores/LotPageStore';

import s from './Header.module.scss';

type HeaderProps = {
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  const { isInFavorite, startToggleFavorite, isInSubscribed, startToggleSubscription, objectType, totalAreaFormatted } =
    lot.value;
  const { isDesktop } = useWidth();

  return (
    <div className={cn(s.header, className)}>
      <div className={s.header__title}>
        <Typography.Title level={1} className={s['header__title-text-wrapper']}>
          <span className={cn(s['header__title-text'], totalAreaFormatted && s['header__title_with-area'])}>
            {objectType}
          </span>
          {totalAreaFormatted && <AreaText area={totalAreaFormatted} />}
        </Typography.Title>
        <div className={s.header__actions}>
          <SubscribeButtonMini
            isSubscribed={isInSubscribed.value}
            onClick={startToggleSubscription}
            withoutTooltip={!isDesktop}
          />
          <FavoriteButtonMini
            isFavorite={isInFavorite.value}
            onClick={startToggleFavorite}
            withoutTooltip={!isDesktop}
          />
        </div>
      </div>
      <LotPageTags className={s.header__tags} />
    </div>
  );
};

export default observer(Header);
