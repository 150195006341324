import { computed, makeObservable } from 'mobx';
import qs from 'query-string';

import { FilterOption, LotFiltersResponse } from 'entities/lotFilters';
import { FilterModel } from 'models/FilterModel';
import { FilterModelMulti } from 'models/FilterModelMulti';
import { FilterModelRange } from 'models/FilterModelRange';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ValueModel } from 'models/ValueModel';
import { LotListCommonFiltersModel } from 'stores/LotListCommonFiltersModel';
import { RouterStore } from 'stores/RootStore/RouterStore';
import { BaseResponse } from 'types/meta';
import { convertToArray } from 'utils/convertToArray';

import { apiUrls } from '../../../config/apiUrls';
import { apiStore } from '../../../utils/apiRequest';

import { OrganizerFilterModel } from './OrganizerFilterModel';
import { DEFAULT_AGGREGATED_TYPE } from './config';
import type { LotCatalogQueryParams, ObjectTypeValue } from './types';

type LotListFiltersModelParams = {
  params: URLSearchParams;
  routerStore: RouterStore;
};

export class LotListCatalogFiltersModel extends LotListCommonFiltersModel<LotCatalogQueryParams> {
  readonly loadingFiltersStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingCitiesStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingRegionsStage: LoadingStageModel = new LoadingStageModel();

  readonly search = new ValueModel<string>('');
  readonly aggregatedType = new FilterModel<FilterOption<string>, ObjectTypeValue>(DEFAULT_AGGREGATED_TYPE.id);
  readonly area = new FilterModelRange(null, null);
  readonly price = new FilterModelRange(null, null);
  readonly organizerFilter = new OrganizerFilterModel();
  readonly region = new FilterModelMulti();
  readonly city = new FilterModelMulti();
  private regionFiltersRequest = apiStore.createRequest<{ items: { id: number; name: string }[] }>({
    method: 'GET',
    url: apiUrls.lotListRegionFilters,
  });
  protected cityFiltersRequest = apiStore.createRequest<{ items: { id: number; name: string }[] }>({
    method: 'GET',
    url: apiUrls.lotListCityFilters,
  });

  constructor({ params, routerStore }: LotListFiltersModelParams) {
    const initParams = LotListCatalogFiltersModel.normalizeQueryParams(params);

    super({
      params: initParams,
      routerStore,
    });

    this.search.change(initParams.search);
    this.area.valueMin.change(initParams.area_from);
    this.area.valueMax.change(initParams.area_to);
    this.price.valueMin.change(initParams.price_from);
    this.price.valueMax.change(initParams.price_to);
    this.organizerFilter.organizerInitValue.change(initParams.organizer);

    makeObservable(this, {
      organizer: computed,
      isEmptySideFilters: computed,
      isAllAggregationType: computed,
    });
  }

  get organizer(): FilterModelMulti {
    return this.organizerFilter.organizer;
  }

  get isEmptySideFilters(): boolean {
    return (
      this.auctionType.isEmpty &&
      this.objectType.isEmpty &&
      this.area.isEmpty &&
      this.price.isEmpty &&
      this.status.isEmpty &&
      this.formType.isEmpty &&
      this.region.isEmpty &&
      this.city.isEmpty &&
      this.organizer.isEmpty
    );
  }

  get isAllAggregationType(): boolean {
    return this.params.aggregated_type === DEFAULT_AGGREGATED_TYPE.id;
  }

  // Методы для вызова из компонентов
  setSearchValue = (value?: string): void => {
    this.setFilterParam('search', value ?? this.search.value);
  };

  setObjectType = (value: ObjectTypeValue): void => {
    this.aggregatedType.selectedValue.change(value);
    this.clearSideFilters();

    this.fetchCatalogFiltersOptions();
  };

  async setRegionValue(value: number) {
    this.region.toggleValue(value);
    this.fetchCities();
  }

  setSideFilters = (): void => {
    this.setParams({
      aggregated_type: this.aggregatedType.selectedValue.value,
      auction_type: this.auctionType.selectedValue.value,
      object_type: this.objectType.selectedValue.value,
      price_from: this.price.valueMin.value,
      price_to: this.price.valueMax.value,
      area_from: this.area.valueMin.value,
      area_to: this.area.valueMax.value,
      status: this.status.selectedValue.value,
      form_type: this.formType.selectedValue.value,
      organizer: this.organizer.selectedValue.value,
      region: this.region.selectedValue.value,
      city: this.city.selectedValue.value,
    });
    this.resetOffset();
  };

  clearSideFilters = (): void => {
    this.auctionType.selectedValue.change([]);
    this.area.reset();
    this.price.reset();
    this.status.selectedValue.change([]);
    this.formType.selectedValue.change([]);
    this.organizer.selectedValue.change([]);
    this.objectType.selectedValue.change([]);
    this.region.selectedValue.change([]);
    this.city.selectedValue.change([]);

    this.setSideFilters();
  };

  setAllFilters = (): void => {
    this.setSearchValue();
    this.setSideFilters();
  };

  async init(): Promise<BaseResponse> {
    if (this.loadingFiltersStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.loadingFiltersStage.loading();

    const responses = await Promise.all([
      this.fetchCatalogFiltersOptions(),
      this.fetchRegions(),
      this.fetchCities(),
      this.organizerFilter.fetchOptions(),
    ]);

    if (responses.every((response) => !response.isError)) {
      this.loadingFiltersStage.success();

      return {
        isError: false,
      };
    }

    this.loadingFiltersStage.error();

    return {
      isError: true,
    };
  }

  private fetchCatalogFiltersOptions = async (): Promise<BaseResponse> => {
    const response = await super.fetchFiltersOptions({
      aggregated_type: this.isAllAggregationType ? null : this.params.aggregated_type,
    });

    if (response.isError) {
      return { isError: true };
    }

    this.setFilterOptions(response.data);

    return { isError: false };
  };

  async fetchRegions(): Promise<BaseResponse> {
    if (this.loadingRegionsStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.loadingRegionsStage.loading();

    const response = await this.regionFiltersRequest.call();

    if (response.isError) {
      this.loadingRegionsStage.error();
    } else {
      this.region.setOptions(response.data.items.map((item) => ({ id: item.id, title: item.name })));
      this.loadingRegionsStage.success();
    }

    return response;
  }

  async fetchCities(): Promise<BaseResponse> {
    if (this.loadingCitiesStage.isLoading) {
      this.cityFiltersRequest.cancel();
    }

    this.loadingCitiesStage.loading();

    const response = await this.cityFiltersRequest.call({
      params: { region: this.region.selectedOptions.map((item) => item.id) },
    });

    if (response.isError) {
      this.loadingCitiesStage.error();
    } else {
      this.city.setOptions(response.data.items.map((item) => ({ id: item.id, title: item.name })));
      this.loadingCitiesStage.success();
    }

    return response;
  }

  protected setFilterOptions(filters: LotFiltersResponse['filters']): void {
    const aggregatedTypeOptions = [
      DEFAULT_AGGREGATED_TYPE,
      ...filters.aggregated_type.map((option) => ({
        id: option.slug || option.title,
        title: option.title,
      })),
    ];

    super.setFilterOptions(filters);

    this.aggregatedType.setOptions(aggregatedTypeOptions, this.params.aggregated_type ?? DEFAULT_AGGREGATED_TYPE.id);
    this.area.from.change(filters.square.from);
    this.area.to.change(filters.square.to);
    this.price.from.change(filters.price.from);
    this.price.to.change(filters.price.to);
  }

  static normalizeQueryParams(params: URLSearchParams): LotCatalogQueryParams {
    const paramsObj = qs.parse(params.toString(), { parseNumbers: true }) as unknown as Partial<LotCatalogQueryParams>;

    const normalizedCommonParams = LotListCommonFiltersModel.normalizeCommonQueryParams(paramsObj);

    return {
      ...normalizedCommonParams,
      search: paramsObj.search ?? '',
      area_from: paramsObj.area_from ?? null,
      area_to: paramsObj.area_to ?? null,
      price_from: paramsObj.price_from ?? null,
      price_to: paramsObj.price_to ?? null,
      organizer: paramsObj.organizer ? convertToArray(paramsObj.organizer) : [],
      aggregated_type: paramsObj.aggregated_type ?? DEFAULT_AGGREGATED_TYPE.id,
      region: paramsObj.region ? convertToArray(paramsObj.region) : [],
      city: paramsObj.city ? convertToArray(paramsObj.city) : [],
    };
  }
}
