export const convertStringToNumber = (value: string): number | null => {
  if (value === '') {
    return null;
  }

  const numValue = Number(value);

  if (isNaN(numValue)) {
    return null;
  }

  return numValue;
};
