import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Tabbar } from 'components/Tabbar';
import { ProfileListType } from 'config/routes';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

type ListTypeTabbarProps = {
  className?: string;
};

const ListTypeTabbar = ({ className }: ListTypeTabbarProps): React.ReactElement<ListTypeTabbarProps> | null => {
  const { filters } = useLotListProfileStore();
  const { selectedValue, tabsOptions } = filters.listType;

  const handleListTypeChange = React.useCallback(
    (value: string) => {
      filters.setListType(value as ProfileListType);
    },
    [filters.setListType],
  );

  return (
    <Tabbar
      className={className}
      selected={selectedValue.value}
      tabsOptions={tabsOptions}
      onChange={handleListTypeChange}
      size="middle"
    />
  );
};

export default observer(ListTypeTabbar);
