import { Space, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './MetaItem.module.scss';

type MetaItemProps = {
  icon: string;
  text: string | number;
  alt: string;
  className?: string;
};

const MetaItem = ({ icon, text, alt, className }: MetaItemProps): React.ReactElement<MetaItemProps> => {
  return (
    <Space className={cn(s['meta-item'], className)} size={4}>
      <img src={icon} className={s['meta-item__icon']} alt={alt} />
      <Typography.Text className={s['meta-item__text']}>{text}</Typography.Text>
    </Space>
  );
};

export default React.memo(MetaItem);
