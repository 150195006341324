import { Button, Empty, Typography } from 'antd';
import * as React from 'react';

import { routerUrls } from 'config/routes';
import BaseErrorLayout from 'pages/errors/BaseErrorLayout';

import s from './LotError.module.scss';

const LotError: React.FC = () => {
  return (
    <BaseErrorLayout className={s.error}>
      <Empty
        description={
          <Typography.Title className={s.error__title} level={2}>
            Страница лота не найдена
          </Typography.Title>
        }
      />
      <Button href={routerUrls.lotsList.create()} type="primary">
        Вернуться в каталог
      </Button>
    </BaseErrorLayout>
  );
};

export default React.memo(LotError);
