import { useRef } from 'react';

import { getIsElementOverflow } from 'utils/getIsElementOverflow';

export const useElementOverflow = <E extends HTMLElement>(): [React.RefObject<E>, isOverflow: boolean] => {
  const ref = useRef<E | null>(null);

  const isOverflow = getIsElementOverflow(ref);

  return [ref, isOverflow];
};
