import { Space } from 'antd';
import * as React from 'react';

import { Tag } from 'components/Tag';
import { DEFAULT_STATUS_CONFIG, LotStatus, Status, lotStatusConfig } from 'entities/lot';

type TagsProps = {
  className?: string;
  status: LotStatus;
  auctionFormat?: string;
  additionalTags?: Status[];
};

const Tags: React.FC<TagsProps> = ({ status, className, auctionFormat, additionalTags }) => {
  const { statusTitle, statusTagColor } = lotStatusConfig[status] ?? DEFAULT_STATUS_CONFIG;

  return (
    <Space size={4} wrap className={className}>
      <Tag color={statusTagColor}>{statusTitle}</Tag>
      {auctionFormat && <Tag color="purple">{auctionFormat}</Tag>}
      {additionalTags?.map((tag) => (
        <Tag key={tag.statusTitle} color={tag.statusTagColor}>
          {tag.statusTitle}
        </Tag>
      ))}
    </Space>
  );
};

export default React.memo(Tags);
