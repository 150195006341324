import { debounce } from '@kts-front/utils';
import { computed, makeObservable, action } from 'mobx';

import { FilterOption } from 'entities/lotFilters';

import { ArrayValueModel } from './ArrayValueModel';
import { ValueModel } from './ValueModel';

type ValueItem = FilterOption['id'];
type Value = ValueItem[];

export class FilterModelMulti {
  private readonly optionsRecord: ValueModel<Record<FilterOption['id'], FilterOption>>;
  private readonly searchValueFinal: ValueModel<string> = new ValueModel<string>('');
  readonly searchValue: ValueModel<string> = new ValueModel<string>('');
  readonly selectedValue: ArrayValueModel<ValueItem>;

  constructor(value: Value = [], options: FilterOption[] = []) {
    makeObservable(this, {
      options: computed,
      isEmpty: computed,
      isEmptyOptions: computed,
      selectedOptions: computed,

      setOptions: action,
    });

    this.selectedValue = new ArrayValueModel<ValueItem>(value);
    this.optionsRecord = new ValueModel<Record<FilterOption['id'], FilterOption>>(
      FilterModelMulti.normalizeOptions(options),
    );
  }

  get options(): FilterOption[] {
    // TODO временное решение поиска на фронте
    // в будущем засылать значение поиска на бэк и полностью обновлять опции
    if (this.searchValueFinal.value === '') {
      return Object.values(this.optionsRecord.value);
    }

    return Object.values(this.optionsRecord.value).filter((option) =>
      option.title.toLowerCase().includes(this.searchValueFinal.value.toLowerCase()),
    );
  }

  get selectedOptions(): FilterOption[] {
    return this.selectedValue.value.map((selectedOptionId) => this.optionsRecord.value[selectedOptionId]);
  }

  get isEmpty(): boolean {
    return this.selectedValue.value.length === 0;
  }

  get isEmptyOptions(): boolean {
    return this.options.length === 0;
  }

  setOptions = (options: FilterOption[], initValue?: Value): void => {
    this.optionsRecord.change(FilterModelMulti.normalizeOptions(options));

    // из выбранных значений удаляем те, которых нет в новых опциях
    const sanitizedValue = (initValue || this.selectedValue.value).filter((id) => this.optionsRecord.value[id]);

    this.selectedValue.change(sanitizedValue);
  };

  toggleValue = (id: ValueItem): void => {
    if (this.selectedValue.hasItem(id)) {
      this.selectedValue.removeItem(id);
    } else {
      this.selectedValue.addItem(id);
    }
  };

  handleSearch = (search: string): void => {
    this.searchValue.change(search);
    this.handleSearchChange();
  };

  private handleSearchChange = debounce({
    timeout: 500,
    func: (): void => {
      this.searchValueFinal.change(this.searchValue.value);
    },
  });

  static normalizeOptions(options: FilterOption[]): Record<FilterOption['id'], FilterOption> {
    if (!options) {
      return {};
    }

    return options.reduce<Record<FilterOption['id'], FilterOption>>((acc, option) => {
      acc[option.id] = option;

      return acc;
    }, {});
  }
}
