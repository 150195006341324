export type FilterOption<ID = number> = {
  id: ID;
  title: string;
};

export type RangeFilter = {
  from: number;
  to: number;
};

type LotCommonFilters = {
  auction_type: FilterOption[];
  status: FilterOption[];
  form_type: FilterOption[];
  object_type: FilterOption[];
};

export type LotCommonFiltersResponse = {
  filters: LotCommonFilters;
};

export type LotFiltersResponse = {
  filters: LotCommonFilters & {
    object_type: FilterOption[];
    square: RangeFilter;
    price: RangeFilter;
    aggregated_type: { slug: string; title: string }[];
  };
};

export type LotProfileFiltersResponse = LotCommonFiltersResponse;

export enum SortOption {
  new = 'newness',
  popularity = 'popularity',
  price = 'price',
  bidEndDate = 'bid_end_date',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SortOptionItem = {
  id: SortOption;
  title: string;
  withSortDirection: boolean;
  defaultDirection: SortDirection;
};

export enum ProfileListType {
  favorites = 'favorites',
  bids = 'bids',
  subs = 'subs',
}

export type ProfileListPayload = {
  params: {
    limit: number;
    offset: number;
    order: SortOption;
    order_direction: SortDirection;
  };
  list_type: ProfileListType;
  filters: {
    auction_type: number[];
    form_type: number[];
    status: number[];
  };
};
