import * as React from 'react';

import { ElementId } from 'config/ids';
import { LotModel } from 'models/LotModel';

import Section from '../Section';
import SectionBlock from '../SectionBlock';

type CommonSectionProps = {
  lot: LotModel;
  id: ElementId;
};

const CommonSection: React.FC<CommonSectionProps> = ({ lot, id }) => {
  return (
    <Section id={id} title="Общие сведения">
      <SectionBlock title="Предмет торгов (наименование лота)" description={lot.title} />
      {lot.description && <SectionBlock title="Описание лота" description={lot.description} />}
      {lot.description && <SectionBlock title="Адрес лота" description={lot.lotPageAddress} />}
      <SectionBlock title="Категория объекта" description={lot.objectType} />
      {lot.auctionOrganizer && <SectionBlock title="Организатор" description={lot.auctionOrganizer.name} />}
      <SectionBlock title="Вид договора" description={lot.auctionType} />
    </Section>
  );
};

export default React.memo(CommonSection);
