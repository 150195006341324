import { LoginOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { AnalyticsEvent } from 'entities/analytics';
import { useWidth } from 'hooks/useWidth';
import { useRootStore } from 'stores/RootStore';

import ProfileIconButton from './ProfileIconButton';
import { ProfileMenu } from './ProfileMenu';

import s from './HeaderProfile.module.scss';

type HeaderProfileProps = {
  className?: string;
};

const HeaderProfile = ({ className }: HeaderProfileProps): React.ReactElement<HeaderProfileProps> => {
  const { userStore, authStore, analyticsStore } = useRootStore();
  const { isDesktop } = useWidth();

  const handleLoginButtonClick = React.useCallback(() => {
    authStore.popupController.open();
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const AuthButton = isDesktop ? (
    <Button
      loading={authStore.authorizingStage.isLoading}
      onClick={handleLoginButtonClick}
      type="primary"
      icon={<LoginOutlined />}
    >
      Войти
    </Button>
  ) : (
    <ProfileIconButton className={s['header-profile__button']} onClick={authStore.popupController.open} />
  );

  return (
    <div className={cn(s['header-profile'], className)} id={ElementId.loginButton}>
      {!userStore.authorized ? AuthButton : <ProfileMenu />}
    </div>
  );
};

export default observer(HeaderProfile);
