import { apiUrls } from 'config/apiUrls';
import { ILotImage, LotImage } from 'entities/lot';

// Размер превью на списке лотов
export const LIST_PREVIEW_SIZE = 300;

// Размер превью в деталке лота
export const DETAIL_PREVIEW_SIZE = 1000;

export class ImageModel implements ILotImage {
  readonly id;
  readonly fullUrl;
  readonly listPreviewUrl;
  readonly detailPreviewUrl;
  readonly type;
  readonly alt;

  constructor({ id, fullUrl, listPreviewUrl, detailPreviewUrl, type, alt }: ILotImage) {
    this.id = id;
    this.fullUrl = fullUrl;
    this.listPreviewUrl = listPreviewUrl;
    this.detailPreviewUrl = detailPreviewUrl;
    this.type = type;
    this.alt = alt;
  }

  static fromJson(raw: LotImage, id: number, alt?: string): ImageModel {
    return {
      id,
      type: raw.type,
      fullUrl: raw.url,
      listPreviewUrl: apiUrls.resizeImage({ w: LIST_PREVIEW_SIZE, url: raw.url }),
      detailPreviewUrl: apiUrls.resizeImage({ w: DETAIL_PREVIEW_SIZE, url: raw.url }),
      alt: alt ?? '',
    };
  }
}
