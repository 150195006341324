import { SortDirection, SortOption, SortOptionItem } from 'entities/lotFilters';

export const sortOptions: Record<SortOption, SortOptionItem> = {
  [SortOption.new]: {
    id: SortOption.new,
    title: 'Новизна',
    withSortDirection: true,
    defaultDirection: SortDirection.asc,
  },
  [SortOption.popularity]: {
    id: SortOption.popularity,
    title: 'Популярность',
    withSortDirection: false,
    defaultDirection: SortDirection.desc,
  },
  [SortOption.price]: {
    id: SortOption.price,
    title: 'Цена',
    withSortDirection: true,
    defaultDirection: SortDirection.asc,
  },
  [SortOption.bidEndDate]: {
    id: SortOption.bidEndDate,
    title: 'Дата окончания',
    withSortDirection: true,
    defaultDirection: SortDirection.desc,
  },
};
