import { ValueModel } from 'models/ValueModel';
import { emailValidator } from 'utils/validator';

export class UserEditModel {
  readonly email: ValueModel<string> = new ValueModel<string>('', [emailValidator]);

  validate(): boolean {
    return this.email.validate();
  }

  reset() {
    this.email.reset();
  }
}
