import cn from 'classnames';
import * as React from 'react';

import s from './AreaText.module.scss';

type AreaTextProps = {
  area: string | null;
  className?: string;
};

const AreaText = ({ area, className }: AreaTextProps): React.ReactElement<AreaTextProps> | null => {
  if (!area) {
    return null;
  }

  return (
    <span className={cn(s['area-text'], className)}>
      {area}&nbsp;м<sup>2</sup>
    </span>
  );
};

export default React.memo(AreaText);
