import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BidCard } from 'components/lot/BidCard';
import { LotCard } from 'components/lot/LotCard';
import { LotsGrid as LotsGridBase } from 'components/lot/LotsGrid';
import { LotsList as LotsListBase } from 'components/lot/LotsList';
import MobileLotCard from 'components/lot/MobileLotCard';
import { LotsListProps as BaseLotsListProps } from 'components/lot/types';
import { useWidth } from 'hooks/useWidth';
import { BidModel } from 'models/BidModel';
import { ListModel } from 'models/ListModel';
import { LotModel } from 'models/LotModel';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

type LotsListProps<M extends LotModel | BidModel> = {
  className?: string;
  lots: ListModel<M, number>;
  emptyText: string;
};

const LotsList = <M extends LotModel | BidModel>({
  className,
  lots,
  emptyText,
}: LotsListProps<M>): React.ReactElement<LotsListProps<M>> => {
  const store = useLotListProfileStore();
  const { isTablet, isDesktop } = useWidth();
  const isBidModel = lots.items[0] instanceof BidModel;
  const cardComponent = isBidModel ? BidCard : isDesktop ? LotCard : MobileLotCard;

  const props = React.useMemo<BaseLotsListProps<M>>(() => {
    return {
      className: className,
      fetchNextItems: store.filters.incrementPage,
      lots: lots,
      emptyText: emptyText,
      CardComponent: cardComponent as React.ComponentType<{ item: M }>,
    };
  }, [emptyText, store.filters.incrementPage, cardComponent]);

  if (!isTablet) {
    return <LotsListBase {...props} />;
  }

  return <LotsGridBase {...props} />;
};

export default observer(LotsList);
