import { Flex, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { AreaText } from 'components/AreaText';
import { ViewsCount } from 'components/ViewsCount';
import FavoriteButtonMini from 'components/favoriteButtons/FavoriteButtonMini';
import { DowngradeIcon } from 'components/icons/DowngradeIcon';
import { Meta } from 'components/lot/Meta';
import SubscribeButtonMini from 'components/subscribeButtons/SubscribeButtonMini';
import { ElementId } from 'config/ids';
import { LotModel } from 'models/LotModel';

import s from './MobileContent.module.scss';

type MobileContentProps = {
  className?: string;
  lot: LotModel;
};

const MobileContent: React.FC<MobileContentProps> = ({ className, lot }) => {
  const handleFavoriteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      lot.startToggleFavorite();
    },
    [lot],
  );

  const handleSubscribeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      lot.startToggleSubscription();
    },
    [lot],
  );

  return (
    <Flex className={cn(s.content, className)} vertical gap={16}>
      <Flex vertical gap={8}>
        <Flex justify="space-between" align="center">
          <Typography.Text className={s.content__date}>{lot.dateAdded}</Typography.Text>
          <ViewsCount viewsCount={lot.viewsCount ?? 0} />
        </Flex>
        <Flex justify="space-between">
          <Flex className={s.content__name} vertical gap={0}>
            <div className={s['content__title-container']}>
              <Typography.Title className={s.content__title} level={3}>
                {lot.objectType}
              </Typography.Title>
              {lot.isDowngradeAuction && <DowngradeIcon className={s['content__downgrade-icon']} />}
            </div>
            <Typography.Text className={s['content__auction-type']}>{lot.auctionType}</Typography.Text>
          </Flex>
          {lot.totalAreaFormatted && (
            <Typography.Text className={s['content__title']}>
              <AreaText area={lot.totalAreaFormatted} />
            </Typography.Text>
          )}
        </Flex>
      </Flex>
      <Typography.Text className={s.content__text}>{lot.lotCardAddress}</Typography.Text>
      <div className={s.content__meta}>
        <Meta floor={lot.floor} totalFloors={lot.amountOfFloors} entranceCount={lot.entrance} />
      </div>
      <Flex className={s.content__footer} justify="space-between" align="center">
        <Typography.Title level={5} className={s.content__title}>
          {lot.priceMinFormatted}
        </Typography.Title>
        <Flex gap={8}>
          <SubscribeButtonMini
            id={ElementId.lotCardSubscribeButtonMobile}
            onClick={handleSubscribeClick}
            isSubscribed={lot.isInSubscribed.value}
            withoutTooltip
          />
          <FavoriteButtonMini
            id={ElementId.lotCardFavoriteButtonMobile}
            onClick={handleFavoriteClick}
            isFavorite={lot.isInFavorite.value}
            withoutTooltip
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default observer(MobileContent);
