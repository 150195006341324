import cn from 'classnames';
import * as React from 'react';

import { DoubleArrow } from 'components/icons/DoubleArrow';

import s from './ArrowIcon.module.scss';

type ArrowIconProps = {
  direction: 'top' | 'bottom';
  className?: string;
};

const ArrowIcon = ({ direction, className }: ArrowIconProps): React.ReactElement<ArrowIconProps> => {
  return <DoubleArrow className={cn(s['arrow-icon'], direction === 'bottom' && s['arrow-icon_bottom'], className)} />;
};

export default ArrowIcon;
