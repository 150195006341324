import { BellFilled, BellOutlined } from '@ant-design/icons';

export const subscribeButtonConfigs: Record<
  'subscribed' | 'unsubscribed',
  { icon: React.ComponentType; tooltipText: string; buttonText: string }
> = {
  subscribed: {
    icon: BellFilled,
    tooltipText: 'Отписаться от уведомлений',
    buttonText: 'Отписаться',
  },
  unsubscribed: {
    icon: BellOutlined,
    tooltipText: 'Подписаться на уведомления',
    buttonText: 'Подписаться',
  },
};
