import * as React from 'react';

import { BaseIconProps } from '../commonProps';

type DoubleArrowProps = BaseIconProps;

const DoubleArrow = (props: DoubleArrowProps): React.ReactElement<DoubleArrowProps> => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.333 7.33333L7.99968 4L4.66634 7.33333M11.333 12L7.99967 8.66667L4.66634 12"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(DoubleArrow);
