import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Wrapper } from 'components/Wrapper';
import LogoIcon from 'components/icons/Logo';
import { routerUrls } from 'config/routes';

import { HeaderMenu } from './HeaderMenu';
import { HeaderProfile } from './HeaderProfile';

import s from './Header.module.scss';

type HeaderProps = {
  className?: string;
};

const Header = ({ className }: HeaderProps): React.ReactElement<HeaderProps> => {
  return (
    <div className={cn(s.header, className)}>
      <Wrapper className={s.header__inner}>
        <Link className={s.header__logo} to={routerUrls.lotsList.create()} aria-label="КупиЛот">
          <LogoIcon />
        </Link>
        <HeaderMenu className={s.header__menu} />
        <HeaderProfile />
      </Wrapper>
    </div>
  );
};

export default React.memo(Header);
