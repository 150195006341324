import { Empty } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { BidModel } from 'models/BidModel';
import { LotModel } from 'models/LotModel';

import { LotCardSkeleton } from '../LotCardSkeleton';
import { LotsListProps } from '../types';

import s from './LotsGrid.module.scss';

const LotsGrid = <T extends LotModel | BidModel>({
  lots,
  fetchNextItems,
  CardComponent,
  emptyText,
  className,
}: LotsListProps<T>): React.ReactElement<LotsListProps<T>> => {
  const { listRef, rowVirtualizer } = useInfiniteScroll({
    length: lots.items.length,
    hasNextPage: lots.hasNextPage,
    isLoading: lots.loadingStage.isLoading,
    estimateItemSize: 1,
    overscan: 4,
    fetchItems: fetchNextItems,
  });

  React.useEffect(() => {
    if (lots.isInitialLoading.value || lots.isReplaceLoading.value) {
      rowVirtualizer.scrollToOffset(0);
    }
  }, [lots.isInitialLoading.value, lots.isReplaceLoading.value]);

  if (lots.isInitialLoading.value) {
    return (
      <div className={cn(s['lots-list'], className)} id={ElementId.lotsList}>
        <div className={s['lots-list__items-row']}>
          <LotCardSkeleton />
          <LotCardSkeleton />
        </div>
      </div>
    );
  }

  if (lots.items.length === 0 && !lots.isInitialLoading.value && !lots.isReplaceLoading.value) {
    return <Empty description={emptyText} />;
  }

  return (
    <div className={cn(s['lots-list'], className)} ref={listRef} id={ElementId.lotsList}>
      <div className={s['lots-list__inner']}>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          <div
            className={s['lots-list__items']}
            style={{
              transform: `translateY(${rowVirtualizer.getVirtualItems()[0]?.start ?? 0}px)`,
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
              // Если это виртуальный элемент для лоадера, то отображаем в нем лоадер
              const isLoader = virtualItem.index > lots.items.length - 1;
              const item = lots.items[virtualItem.index];
              const nextItem = lots.items[virtualItem.index + 1];

              if (virtualItem.index % 2 !== 0) {
                return null;
              }

              return (
                <div
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={rowVirtualizer.measureElement}
                  className={s['lots-list__items-row']}
                >
                  {(isLoader && lots.hasNextPage) || lots.isReplaceLoading.value ? (
                    <>
                      <LotCardSkeleton />
                      <LotCardSkeleton />
                    </>
                  ) : (
                    <>
                      <CardComponent item={item} />
                      {nextItem && <CardComponent item={nextItem} />}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LotsGrid);
