import { TimelinePhase, TimelineServer } from 'entities/timeline';

import { TimelineStageModel } from './TimelineStageModel';

export class TimelineModel {
  readonly stages: TimelineStageModel[] = [];

  constructor(stages: TimelineStageModel[]) {
    this.stages = stages;
  }

  get bidEndTimeStage(): TimelineStageModel | null {
    return this.stages.find((stage) => stage.phase === TimelinePhase.bidEndTime) || null;
  }
  get bidStartTimeStage(): TimelineStageModel | null {
    return this.stages.find((stage) => stage.phase === TimelinePhase.bidStartTime) || null;
  }

  get activePhase(): TimelinePhase | null {
    if (!this.stages.length) {
      return null;
    }
    const currentDate = new Date();
    let phase: TimelinePhase = TimelinePhase.bidStartTime;

    this.stages.forEach((item) => {
      if (item.date instanceof Date && currentDate > item.date) {
        phase = item.phase;
      }
    });

    return phase;
  }

  get activePhaseIndex(): number {
    const activeTimelineStageModel = this.stages.find((stage) => stage.phase === this.activePhase);

    if (activeTimelineStageModel) {
      return this.stages.indexOf(activeTimelineStageModel);
    }

    return 0;
  }

  static fromJson(raw: TimelineServer): TimelineModel {
    const stages: TimelineStageModel[] = Object.entries(raw).map(([key, value]) =>
      TimelineStageModel.fromJson({ title: value.title, date: value.date, phase: key as TimelinePhase }),
    );

    return new TimelineModel(stages);
  }
}
