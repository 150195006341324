import * as React from 'react';

import { SideFilters as SideFiltersBase } from 'components/SideFilters';

import { ActionButtons } from './ActionButtons';
import { Area } from './Filters/Area';
import { AuctionType } from './Filters/AuctionType';
import { City } from './Filters/City';
import { FormType } from './Filters/FormType';
import { ObjectType } from './Filters/ObjectType';
import { Organizer } from './Filters/Organizer';
import { Price } from './Filters/Price';
import { Region } from './Filters/Region';
import { Status } from './Filters/Status';

type SideFiltersProps = {
  onFiltersClose: VoidFunction;
  className?: string;
};

const SideFilters = ({ onFiltersClose, className }: SideFiltersProps): React.ReactElement<SideFiltersProps> => {
  return (
    <SideFiltersBase
      className={className}
      filters={
        <>
          <AuctionType />
          <ObjectType />
          <Area />
          <Price />
          <Status />
          <FormType />
          <Organizer />
          <Region />
          <City />
        </>
      }
      actionButtons={<ActionButtons onFiltersClose={onFiltersClose} />}
      onFiltersClose={onFiltersClose}
    />
  );
};

export default SideFilters;
