import { IReactionDisposer } from 'mobx';
import PubSub from 'pubsub-js';

import { ILocalStore } from 'types/ILocalStore';
import { AppEvent } from 'types/appEvent';

export abstract class LocalStore implements ILocalStore {
  private pubSubTokens: Partial<Record<AppEvent, string>> = {};
  private reactionDisposers: IReactionDisposer[] = [];

  addReaction(reaction: IReactionDisposer): void {
    this.reactionDisposers.push(reaction);
  }

  addReactions(reactions: IReactionDisposer[]): void {
    for (const r of reactions) {
      this.reactionDisposers.push(r);
    }
  }

  subscribe(event: AppEvent, handler: (data: Record<string, any>) => void): void {
    // @ts-ignore
    this.pubSubTokens[event] = PubSub.subscribe(event, (_, data) => handler(data));
  }

  unsubscribe(event: AppEvent): void {
    const token = this.pubSubTokens[event];

    if (token) {
      PubSub.unsubscribe(token);
    }
  }

  unsubscribeAll(): void {
    Object.keys(this.pubSubTokens).forEach((event) => this.unsubscribe(event as AppEvent));
  }

  publish(event: AppEvent, data: Record<string, any>): void {
    PubSub.publish(event, data);
  }

  destroyReactions(): void {
    this.reactionDisposers.forEach((disposer) => disposer());
  }

  destroy(): void {
    this.unsubscribeAll();
    this.destroyReactions();
  }
}
