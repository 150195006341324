import { ThemeConfig } from 'antd';

import { colorPrimary } from 'styles/colors';

export const themeConfig: ThemeConfig = {
  token: {
    colorPrimary,
    borderRadius: 2,
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    List: {
      itemPaddingLG: '20px',
    },
  },
};
