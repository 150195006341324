import { UserServer, IUser } from 'entities/user';

export class UserModel implements IUser {
  readonly phone: string;
  readonly userId: number;
  readonly email: string | null;

  constructor(user: IUser) {
    this.phone = user.phone;
    this.userId = user.userId;
    this.email = user.email;
  }

  get hasEmail(): boolean {
    return Boolean(this.email);
  }

  static fromJson(raw: UserServer) {
    return new UserModel({
      phone: raw.phone,
      userId: raw.user_id,
      email: raw.email,
    });
  }
}
