import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useLotPageStore } from 'stores/LotPageStore';

import Card from './Card';
import {
  APPLICATION_SEND_INVEST_TEXT,
  APPLICATION_SEND_FOLLOW_TEXT,
  DEFAULT_INVEST_TEXT,
  DEFAULT_FOLLOW_TEXT,
} from './configs';

type ApplicationProps = {
  className?: string;
};

const Application: React.FC<ApplicationProps> = ({ className }) => {
  const { sendFollowApplication, sendInvestApplication, applicationInvestLoadStage, applicationFollowLoadStage, lot } =
    useLotPageStore();

  return (
    <div className={className}>
      <Card
        title="Инвестиционная привлекательность объекта"
        description={lot.value.isInvestBidExistedValue ? APPLICATION_SEND_INVEST_TEXT : DEFAULT_INVEST_TEXT}
        onClick={sendInvestApplication}
        isLoading={applicationInvestLoadStage.isLoading}
        isApplicationSend={lot.value.isInvestBidExistedValue}
      />
      <Card
        title="Сопровождение сделки"
        description={lot.value.isFollowBidExistedValue ? APPLICATION_SEND_FOLLOW_TEXT : DEFAULT_FOLLOW_TEXT}
        onClick={sendFollowApplication}
        isLoading={applicationFollowLoadStage.isLoading}
        isApplicationSend={lot.value.isFollowBidExistedValue}
      />
    </div>
  );
};

export default observer(Application);
