import { Checkbox, Tooltip } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { FilterOption } from 'entities/lotFilters';
import { useElementOverflow } from 'hooks/useElementOverflow';

import s from './MultiSelectItem.module.scss';

type MultiSelectItemProps = {
  option: FilterOption;
  onChange: (id: FilterOption['id']) => void;
  checked: boolean;
  className?: string;
};

const MultiSelectItem = ({
  option,
  onChange,
  checked,
  className,
}: MultiSelectItemProps): React.ReactElement<MultiSelectItemProps> => {
  const handleChange = React.useCallback(() => {
    onChange(option.id);
  }, [onChange, option]);

  const [titleRef, isTooltipShown] = useElementOverflow<HTMLDivElement>();

  return (
    <Checkbox className={cn(s['multi-select-item'], className)} checked={checked} onChange={handleChange}>
      <Tooltip title={isTooltipShown ? option.title : null} mouseEnterDelay={0.5}>
        <div className={s['multi-select-item__title']} ref={titleRef}>
          {option.title}
        </div>
      </Tooltip>
    </Checkbox>
  );
};

export default MultiSelectItem;
