import * as React from 'react';

import { ElementId } from 'config/ids';

export const useDisableRootScroll = (initialState = false) => {
  const [isScrollHide, setIsScrollHide] = React.useState<boolean>(initialState);

  const setScrollHide = React.useCallback(
    (value: boolean) => {
      setIsScrollHide(value);
    },
    [setIsScrollHide],
  );

  React.useEffect(() => {
    const root: HTMLElement | null = document.querySelector(`#${ElementId.root}`);

    if (!root) {
      return;
    }

    if (isScrollHide) {
      root.style.overflow = 'hidden';
      root.style.height = '100vh';
    } else {
      root.style.overflow = 'initial';
      root.style.height = 'initial';
    }

    () => {
      if (isScrollHide) {
        root.style.overflow = 'initial';
        root.style.height = 'initial';
      }
    };
  }, [isScrollHide]);

  return { setScrollHide };
};
