import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { SortDirection, SortOption } from 'entities/lotFilters';
import sortIcon from 'icons/sort.svg';

import s from './SortItem.module.scss';

type SortItemProps = {
  id: SortOption;
  label: string;
  active: boolean;
  withSortDirection?: boolean;
  sortDirection?: SortDirection;
  onClick: (id: SortOption) => void;
  className?: string;
};

const SortItem = ({
  id,
  label,
  active,
  withSortDirection = false,
  sortDirection = SortDirection.asc,
  onClick,
  className,
}: SortItemProps): React.ReactElement<SortItemProps> => {
  const handleClick = React.useCallback(() => {
    onClick(id);
  }, []);

  return (
    <Button
      type="text"
      className={cn(
        s['sort-item'],
        active && s['sort-item_active'],
        withSortDirection && s['sort-item_with-icon'],
        className,
      )}
      onClick={handleClick}
    >
      {label}
      {active && withSortDirection && (
        <img
          src={sortIcon}
          className={cn(s['sort-item__icon'], sortDirection === SortDirection.desc && s['sort-item__icon_flipped'])}
        />
      )}
    </Button>
  );
};

export default React.memo(SortItem);
