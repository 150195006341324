/* eslint-disable import/namespace */
import { GlobalOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import cn from 'classnames';
import * as Flags from 'country-flag-icons/react/3x2';
import { FilterFunc } from 'rc-select/lib/Select';
import * as React from 'react';
import { getCountryCallingCode, isSupportedCountry } from 'react-phone-number-input';

import s from './CountrySelectComponent.module.scss';

type Option = { label: React.ReactElement; value: string };

type CountrySelectComponentProps = {
  name: string;
  value: string;
  onChange: (value: string) => void;
  onFocus: VoidFunction;
  onBlur: VoidFunction;
  options: { label: string; value: string }[];
  disabled: boolean;
  readOnly: boolean;
  className: string;
};

const CountrySelectComponent: React.FC<CountrySelectComponentProps> = ({ className, options, ...props }) => {
  const selectOptions = React.useMemo<Option[]>(() => {
    return options.map((option) => ({
      label: (
        <span className={s.select__option}>
          {isSupportedCountry(option.value) ? Flags[option.value]({ width: 20, height: 20 }) : <GlobalOutlined />}
          {`${option.label} ${isSupportedCountry(option.value) ? `+${getCountryCallingCode(option.value)}` : ''}`}
        </span>
      ),
      value: option.value || option.label,
    }));
  }, []);

  const filterOption = React.useCallback(
    (input: string, option: Option) =>
      (option?.label ?? '').props.children[1].toLowerCase().startsWith(input.toLowerCase()),
    [],
  );

  return (
    <Select
      filterOption={filterOption as FilterFunc<Option>}
      showSearch
      className={cn(className, s.select)}
      popupClassName={s.select__popup}
      options={selectOptions}
      {...props}
    />
  );
};

export default React.memo(CountrySelectComponent);
