import cn from 'classnames';
import * as React from 'react';

import s from './SliderIcon.module.scss';

type SliderIconProps = {
  className?: string;
};

const SliderIcon = ({ className }: SliderIconProps): React.ReactElement<SliderIconProps> => {
  return (
    <div className={cn(s['slider-icon'], className)}>
      <div className={s['slider-icon__window']}>
        <div className={s['slider-icon__window-slide']} />
        <div className={s['slider-icon__window-slide']} />
        <div className={s['slider-icon__window-slide']} />
      </div>
      <svg
        className={s['slider-icon__hand']}
        width="25"
        height="29"
        viewBox="0 0 25 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.39579 28.7575C8.39579 28.7575 4.28579 21.4375 2.55579 18.2675C0.255791 14.0475 -0.12422 12.2675 1.46579 11.3875C2.45579 10.8375 3.82579 11.0675 4.66579 12.5275L6.62579 15.6475V3.4175C6.62579 3.4175 6.50579 0.1875 8.80579 0.1875C11.2558 0.1875 11.0458 3.4175 11.0458 3.4175V9.3575C11.0458 9.3575 12.3358 8.4275 13.8458 8.8475C14.6158 9.0575 15.5158 9.4275 15.9958 10.6475C15.9958 10.6475 19.0658 9.1575 20.5958 12.3275C20.5958 12.3275 24.1358 11.6275 24.1358 15.2975C24.1358 18.9675 19.7158 28.7575 19.7158 28.7575H8.39579Z"
          fill="#BEBEBE"
        />
      </svg>
    </div>
  );
};

export default SliderIcon;
