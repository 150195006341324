import { Button, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import { TooltipRenderProps } from 'react-joyride';

import s from './Tooltip.module.scss';

type TooltipProps = TooltipRenderProps & {
  className?: string;
};

const Tooltip = React.forwardRef(function Tooltip({
  step,
  index,
  isLastStep,
  tooltipProps,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  className,
}: TooltipProps): React.ReactElement<TooltipProps> {
  return (
    <div className={cn(s.tooltip, className)} {...tooltipProps}>
      <Typography.Paragraph className={s.tooltip__content}>{step.content}</Typography.Paragraph>
      <div className={s.tooltip__footer}>
        {index > 0 && (
          <Button className={s.tooltip__button_back} type="default" {...backProps}>
            Назад
          </Button>
        )}
        {isLastStep ? (
          <Button className={s.tooltip__button_close} type="primary" {...closeProps}>
            Закрыть
          </Button>
        ) : (
          <>
            <Button className={s.tooltip__button_next} type="primary" {...primaryProps}>
              Следующий шаг
            </Button>
            <Button className={s.tooltip__button_skip} type="text" {...skipProps}>
              Пропустить
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

export default Tooltip;
