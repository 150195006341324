import * as React from 'react';
import { useParams } from 'react-router-dom';

import Loader from 'components/PageLoader';
import useAsyncLocalStore from 'hooks/useAsycLocalStore';
import LotError from 'pages/LotPage/LotError';
import { useRootStore } from 'stores/RootStore';
import { LoadingStage } from 'types/meta';

import { LotPageStore } from './LotPageStore';

const LotPageContext = React.createContext<LotPageStore | null>(null);

type LotPageProviderProps = {
  children: React.ReactNode;
};

export const LotPageProvider: React.FC<LotPageProviderProps> = ({ children }) => {
  const params = useParams();
  const rootStore = useRootStore();
  const storeCtx = useAsyncLocalStore(() => LotPageStore.initializeAsync(Number(params.id), rootStore));

  if (storeCtx.stage === LoadingStage.error) {
    return <LotError />;
  }

  if (storeCtx.stage !== LoadingStage.success) {
    return <Loader />;
  }

  return <LotPageContext.Provider value={storeCtx.ctx.store}>{children}</LotPageContext.Provider>;
};

export const useLotPageStore = () => {
  const context = React.useContext(LotPageContext);

  if (!context) {
    throw new Error('useLotPage is not in LotPageProvider');
  }

  return context;
};
