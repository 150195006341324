/**
 * Функция, которая всегда возвращает массив
 */

export const convertToArray = <T extends number | string>(input: T | T[]): T[] => {
  if (typeof input === 'number' || typeof input === 'string') {
    return [input];
  }

  return input;
};
