import { SortDirection, SortOption } from 'entities/lotFilters';

export enum DropdownSortOptions {
  popularity = 'Популярные',
  newFirst = 'Сначала новые',
  oldFirst = 'Сначала старые',
  priceAsc = 'По возрастанию цены',
  priceDes = 'По убыванию цены',
  date = 'По дате окончания',
}

export const DROPDOWN_ITEMS: { key: DropdownSortOptions; sortOption: SortOption; direction: SortDirection }[] = [
  {
    key: DropdownSortOptions.popularity,
    sortOption: SortOption.popularity,
    direction: SortDirection.desc,
  },
  {
    key: DropdownSortOptions.newFirst,
    sortOption: SortOption.new,
    direction: SortDirection.desc,
  },
  {
    key: DropdownSortOptions.oldFirst,
    sortOption: SortOption.new,
    direction: SortDirection.asc,
  },
  {
    key: DropdownSortOptions.priceAsc,
    sortOption: SortOption.price,
    direction: SortDirection.asc,
  },
  {
    key: DropdownSortOptions.priceDes,
    sortOption: SortOption.price,
    direction: SortDirection.desc,
  },
  {
    key: DropdownSortOptions.date,
    sortOption: SortOption.bidEndDate,
    direction: SortDirection.desc,
  },
];
