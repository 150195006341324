import { action, computed, makeObservable, observable } from 'mobx';

export class ArrayValueModel<T extends string | number> {
  protected _value: T[];

  constructor(value?: T[]) {
    makeObservable<this, '_value'>(this, {
      _value: observable,

      value: computed,

      change: action.bound,
      addItem: action.bound,
      removeItem: action.bound,
    });

    this._value = value ?? [];
  }

  get value(): T[] {
    return this._value;
  }

  get isEmpty(): boolean {
    return this._value.length === 0;
  }

  change(value: T[]): void {
    this._value = value;
  }

  hasItem(item: T): boolean {
    return this._value.includes(item);
  }

  addItem(item: T): void {
    this._value.push(item);
  }

  removeItem(item: T): void {
    const index = this._value.indexOf(item);

    if (index === -1) {
      return;
    }

    this._value.splice(index, 1);
  }
}
