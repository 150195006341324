import { AnalyticsObj } from 'entities/analytics';

import { IAnalyticsStore } from './types';

export class AnalyticsStore implements IAnalyticsStore {
  trackEvent({ event, payload }: AnalyticsObj): void {
    window.gtag('event', event, {
      event_name: event,
      ...payload,
    });
  }
}
