import { IReactionDisposer, computed, makeObservable, reaction } from 'mobx';
import qs from 'query-string';

import { apiUrls } from 'config/apiUrls';
import { ProfileListType } from 'config/routes';
import { FilterOption, LotProfileFiltersResponse } from 'entities/lotFilters';
import { FilterModel } from 'models/FilterModel';
import { LotListCommonFiltersModel } from 'stores/LotListCommonFiltersModel';
import { RouterStore } from 'stores/RootStore/RouterStore';
import { BaseResponse } from 'types/meta';

import { apiStore } from '../../../utils/apiRequest';

import { DEFAULT_LIST_TYPE, listTypeOptions } from './config';
import { LotProfileQueryParams } from './types';

type LotListProfileFiltersModelParams = {
  params: URLSearchParams;
  routerStore: RouterStore;
};

export class LotListProfileFiltersModel extends LotListCommonFiltersModel<LotProfileQueryParams> {
  readonly listType = new FilterModel<FilterOption<ProfileListType>, ProfileListType>(
    DEFAULT_LIST_TYPE,
    listTypeOptions,
  );

  private readonly disposers: IReactionDisposer[] = [];

  private profileFiltersRequest = apiStore.createRequest<LotProfileFiltersResponse>({
    method: 'GET',
    url: apiUrls.lotListProfileFilters,
  });

  constructor({ params, routerStore }: LotListProfileFiltersModelParams) {
    const initParams = LotListProfileFiltersModel.normalizeQueryParams(params);

    super({
      params: initParams,
      routerStore,
    });

    makeObservable(this, {
      isEmptySideFilters: computed,
      isEmptySideFiltersOptions: computed,
    });

    this.disposers.push(
      reaction(
        () => this.listType.selectedValue.value,
        () => this.fetchProfileFiltersOptions(),
      ),
    );

    this.listType.selectedValue.change(initParams.list_type);
  }

  get isEmptySideFilters(): boolean {
    // TODO после добавления фильтра по субъекту — добавить условие сюда
    return this.auctionType.isEmpty && this.status.isEmpty && this.formType.isEmpty;
  }

  get isEmptySideFiltersOptions(): boolean {
    // TODO после добавления фильтра по субъекту — добавить условие сюда
    return this.auctionType.isEmptyOptions && this.status.isEmptyOptions && this.formType.isEmptyOptions;
  }

  setListType = (value: ProfileListType): void => {
    this.listType.selectedValue.change(value);
    this.resetSort();
    this.clearSideFilters();
  };

  setSideFilters = (): void => {
    this.setParams({
      list_type: this.listType.selectedValue.value,
      auction_type: this.auctionType.selectedValue.value,
      status: this.status.selectedValue.value,
      form_type: this.formType.selectedValue.value,
    });
    this.resetOffset();
  };

  clearSideFilters = (): void => {
    this.auctionType.selectedValue.change([]);
    this.status.selectedValue.change([]);
    this.formType.selectedValue.change([]);
    this.objectType.selectedValue.change([]);

    this.setSideFilters();
  };

  init(): void {
    this.fetchProfileFiltersOptions();
  }

  protected async fetchProfileFiltersOptions(): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      this.profileFiltersRequest.cancel();
    }

    this.loadingStage.loading();

    const response = await this.profileFiltersRequest.call({
      params: {
        list_type: this.listType.selectedValue.value,
      },
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this.setFilterOptions(response.data.filters);
    this.loadingStage.success();

    return {
      isError: false,
    };
  }

  static normalizeQueryParams(params: URLSearchParams): LotProfileQueryParams {
    const paramsObj = qs.parse(params.toString(), { parseNumbers: true }) as unknown as Partial<LotProfileQueryParams>;

    const normalizedCommonParams = LotListCommonFiltersModel.normalizeCommonQueryParams(paramsObj);

    return {
      ...normalizedCommonParams,
      list_type: paramsObj.list_type ? paramsObj.list_type : DEFAULT_LIST_TYPE,
    };
  }

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
