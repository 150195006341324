import { Space, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './Section.module.scss';

type SectionProps = {
  className?: string;
  contentClassName?: string;
  title: string;
  id: string;
};

const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({
  children,
  contentClassName,
  className,
  title,
  id,
}) => {
  return (
    <div id={id} className={cn(className, s.section)}>
      <Typography.Title className={s.section__title} level={3}>
        {title}
      </Typography.Title>
      <Space className={cn(s.section__content, contentClassName)} direction="vertical">
        {children}
      </Space>
    </div>
  );
};

export default React.memo(Section);
