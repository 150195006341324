import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './PageTitle.module.scss';

type TitleProps = React.PropsWithChildren<{
  className?: string;
}>;

const PageTitle = ({ children, className }: TitleProps): React.ReactElement<TitleProps> => {
  return <Typography.Title className={cn(s.title, className)}>{children}</Typography.Title>;
};

export default PageTitle;
