import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { MENU_ITEMS } from 'components/Header/HeaderMenu/config';

/**
 * Хук предоставляет ключи активных в данный момент пунктов верхнего меню
 * для компонента HeaderMenu
 */
export const useMenuKeys = (): string[] => {
  const location = useLocation();

  const selectedKeys = useMemo(() => {
    return MENU_ITEMS.reduce<string[]>((acc, menuItem) => {
      if (matchPath(menuItem.pattern, location.pathname)) {
        acc.push(menuItem.url);
      }

      return acc;
    }, []);
  }, [location]);

  return selectedKeys;
};
