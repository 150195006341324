import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ProfileListType } from 'config/routes';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

import { BidsList } from './BidsList';
import { FavoritesList } from './FavoritesList';
import { SubscriptionsList } from './SubscriptionsList';

type ListWithFiltersProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const ListWithFilters = ({ ...props }: ListWithFiltersProps): React.ReactElement<ListWithFiltersProps> => {
  const store = useLotListProfileStore();

  if (store.filters.listType.selectedValue.value === ProfileListType.favorites) {
    return <FavoritesList {...props} />;
  }

  if (store.filters.listType.selectedValue.value === ProfileListType.bids) {
    return <BidsList {...props} />;
  }

  return <SubscriptionsList {...props} />;
};

export default observer(ListWithFilters);
