import cn from 'classnames';
import * as React from 'react';

import { ListControlsBar } from 'components/ListControlsBar';
import { useDisableRootScroll } from 'hooks/useDisableRootScroll';
import useToggleState from 'hooks/useToggleState';

import s from './ListWithFilters.module.scss';

type ListWithFiltersProps = {
  SideFilters: React.ComponentType<{ onFiltersClose: VoidFunction }>;
  sortComponent: React.ReactNode;
  listComponent: React.ReactNode;
  isListEmpty: boolean;
  isListTotalEmpty?: boolean;
  isFilterOptionsEmpty?: boolean;
  isFiltersCollapsed?: boolean;
  className?: string;
};

const ListWithFilters = ({
  SideFilters,
  sortComponent,
  listComponent,
  isListEmpty,
  isListTotalEmpty = false,
  isFilterOptionsEmpty = false,
  isFiltersCollapsed = false,
  className,
}: ListWithFiltersProps): React.ReactElement<ListWithFiltersProps> => {
  const { opened: isFiltersShown, open, close } = useToggleState();
  const { setScrollHide } = useDisableRootScroll(isFiltersShown);

  React.useEffect(() => {
    setScrollHide(isFiltersShown);
  }, [isFiltersShown]);

  return (
    <div
      className={cn(
        s.container,
        isListTotalEmpty && s['container_no-filters'],
        isFiltersCollapsed && s['container_with-filters-collapsed'],
        className,
      )}
    >
      {!(isListTotalEmpty || isFilterOptionsEmpty) && (
        <div className={cn(s.container__filters, isFiltersShown && s.container__filters_shown)}>
          <SideFilters onFiltersClose={close} />
        </div>
      )}
      {/* Заглушка для колонки фильтров */}
      {isFilterOptionsEmpty && !isListTotalEmpty && <div className={cn(s.container__filters)} />}
      <div className={s.container__list}>
        {!isListEmpty && (
          <ListControlsBar
            onFiltersClick={open}
            sortComponent={sortComponent}
            className={cn(isFiltersCollapsed && s['container__list-controls_collapsed'])}
          />
        )}
        {listComponent}
      </div>
    </div>
  );
};

export default ListWithFilters;
