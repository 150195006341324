import { Breadcrumb, Space } from 'antd';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { PageContainer } from 'components/PageContainer';
import { routerUrls } from 'config/routes';
import { LotPageProvider } from 'stores/LotPageStore/context';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';

import Application from './Application';
import CommonInfo from './CommonInfo';
import LotImagesCarousel from './LotImagesCarousel';
import LotTimeline from './LotTimeline';
import MainInfo from './MainInfo';
import Header from './MainInfo/Header';
import MobileCarousel from './MobileCarousel';
import MobileTimeline from './MobileTimeline';

import s from './LotPage.module.scss';

type LotPageProps = {
  className?: string;
};

const LotPage = ({ className }: LotPageProps): React.ReactElement<LotPageProps> => {
  const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
    { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
    { title: 'Страница лота' },
  ];

  const {
    onboardingStore: { isOnboardingPassedModel, isInitialOnboardingPassedModel, resumeOnboardingOnLotPage },
  } = useRootStore();

  React.useEffect(() => {
    if (!isOnboardingPassedModel.value && isInitialOnboardingPassedModel.value) {
      resumeOnboardingOnLotPage();
    }
  }, []);

  return (
    <LotPageProvider>
      <PageContainer className={cn(s['lot-page'], className)}>
        <Breadcrumb
          className={s['lot-page__breadcrumbs']}
          itemRender={breadcrumbItemsRender}
          items={BREADCRUMB_ITEMS}
        />
        <Header className={s['lot-page__header']} />
        <MobileCarousel />
        <Space size="large" className={s['lot-page__content']}>
          <LotImagesCarousel className={s['lot-page__carousel']} />
          <MainInfo />
        </Space>
        <LotTimeline className={s['lot-page__timeline']} />
        <MobileTimeline className={s['lot-page__slider']} />
        <Application className={s['lot-page__application']} />
      </PageContainer>
      <CommonInfo className={s['lot-page__common-info']} />
    </LotPageProvider>
  );
};

export default observer(LotPage);
