import { action, computed, makeObservable, runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { UserServer } from 'entities/user';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ToggleModel } from 'models/ToggleModel';
import { UserEditModel } from 'models/UserEditModel';
import { UserModel } from 'models/UserModel';
import { ValueModel } from 'models/ValueModel';
import { BaseResponse } from 'types/meta';
import { api } from 'utils/api';

import { IUserStore } from './types';

export class UserStore implements IUserStore {
  private _userModel: ValueModel<UserModel | null> = new ValueModel<UserModel | null>(null);

  readonly userEditModel = new UserEditModel();
  readonly editStage: LoadingStageModel = new LoadingStageModel();
  readonly emailPopupController: ToggleModel = new ToggleModel();

  constructor() {
    makeObservable(this, {
      user: computed,
      authorized: computed,

      setUser: action.bound,
      editUser: action.bound,
    });
  }

  get user(): UserModel | null {
    return this._userModel.value;
  }

  get authorized(): boolean {
    return this._userModel.value !== null;
  }

  resetUser = () => {
    this.setUser(null);
    this.userEditModel.reset();
  };

  closeEmailPopup = () => {
    this.emailPopupController.close();
    this.userEditModel.reset();
  };

  setUser(user: UserModel | null) {
    this._userModel.change(user);
  }

  async editUser(): Promise<BaseResponse> {
    if (this.editStage.isLoading) {
      return { isError: true };
    }

    if (this.userEditModel.validate()) {
      return { isError: true };
    }

    this.editStage.loading();

    const response = await api<UserServer>({
      url: apiUrls.editUser,
      method: 'POST',
      data: {
        email: this.userEditModel.email.value,
      },
    });

    return runInAction(() => {
      if (response.isError) {
        this.editStage.error();

        if (response.data?.responseCode === 409) {
          this.userEditModel.email.changeError(
            'Почта с таким адресом уже занята. Введите другую или обратитесь в поддержку.',
          );
        }

        return { isError: true };
      }

      const updatedUser = UserModel.fromJson(response.data);
      this.setUser(updatedUser);

      this.closeEmailPopup();
      this.editStage.success();

      return { isError: false };
    });
  }
}
