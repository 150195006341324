import cn from 'classnames';
import * as React from 'react';

import { ElementId } from 'config/ids';

import { Header } from './Header';

import s from './SideFilters.module.scss';

type SideFiltersProps = {
  filters: React.ReactNode;
  actionButtons: React.ReactNode;
  onFiltersClose: VoidFunction;
  className?: string;
};

const SideFilters = ({
  filters,
  actionButtons,
  onFiltersClose,
  className,
}: SideFiltersProps): React.ReactElement<SideFiltersProps> => {
  return (
    <aside className={cn(s['side-filters'], className)} id={ElementId.filters}>
      <div className={s['side-filters__content']}>
        <Header className={s['side-filters__header']} onFiltersClose={onFiltersClose} />
        <div className={s['side-filters__filters']}>{filters}</div>
      </div>
      <div className={s['side-filters__action-buttons']}>{actionButtons}</div>
    </aside>
  );
};

export default SideFilters;
