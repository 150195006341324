import { E164Number } from 'libphonenumber-js/core';
import { action, computed, makeObservable, runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { AnalyticsEvent } from 'entities/analytics';
import { UserServer } from 'entities/user';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ToggleModel } from 'models/ToggleModel';
import { UserModel } from 'models/UserModel';
import { ValueModel } from 'models/ValueModel';
import { IAnalyticsStore } from 'stores/AnalyticsStore';
import { IUserStore } from 'stores/UserStore';
import { BaseResponse, BaseResponseCodeType } from 'types/meta';
import { api } from 'utils/api';
import { getErrorMessage } from 'utils/getErrorMessage';
import { phoneValidator } from 'utils/validator';

import { IAuthStore } from './types';

export enum AuthStep {
  initial = 'initial',
  confirm = 'confirm',
}

export enum ApiAuthError {
  invalid_code_error = 'invalid_code_error',
  sending_timeout_error = 'sending_timeout_error',
  expiration_code_error = 'expiration_code_error',
  not_found_code_error = 'not_found_code_error',
  attempts_timeout_error = 'attempts_timeout_error',
}

const errorMap: Record<ApiAuthError, string> = {
  [ApiAuthError.invalid_code_error]: 'Неверный код',
  [ApiAuthError.sending_timeout_error]: 'Повторно отправить код можно через 30с',
  [ApiAuthError.expiration_code_error]: 'Код устарел, запросите новый',
  [ApiAuthError.not_found_code_error]: 'Неизвестная ошибка. Попробуйте еще раз или измените номер телефона',
  [ApiAuthError.attempts_timeout_error]: 'Превышено количество попыток ввода кода',
};

type AuthStoreParams = {
  userStore: IUserStore;
  analyticsStore: IAnalyticsStore;
};

const SECONDS_TO_GET_NEW_CODE = 30;

export class AuthStore implements IAuthStore {
  readonly phone: ValueModel<E164Number | null> = new ValueModel<E164Number | null>(null, [phoneValidator]);
  readonly code: ValueModel<string> = new ValueModel<string>('');
  readonly authStep: ValueModel<AuthStep> = new ValueModel<AuthStep>(AuthStep.initial);
  readonly timer: ValueModel<ReturnType<typeof setInterval> | null> = new ValueModel<ReturnType<
    typeof setInterval
  > | null>(null);
  readonly secondsCounter: ValueModel<number> = new ValueModel(SECONDS_TO_GET_NEW_CODE);

  readonly popupController: ToggleModel = new ToggleModel();

  readonly userStore: IUserStore;
  readonly analyticsStore: IAnalyticsStore;

  readonly registerStage: LoadingStageModel = new LoadingStageModel();
  readonly codeLoadingStage: LoadingStageModel = new LoadingStageModel();
  readonly checkCodeStage: LoadingStageModel = new LoadingStageModel();
  readonly logoutStage: LoadingStageModel = new LoadingStageModel();
  readonly authorizingStage: LoadingStageModel = new LoadingStageModel();

  constructor({ userStore, analyticsStore }: AuthStoreParams) {
    this.userStore = userStore;
    this.analyticsStore = analyticsStore;

    makeObservable(this, {
      setStep: action.bound,
      register: action.bound,
      checkCode: action.bound,
      startSecondsCounter: action.bound,
      getCode: action.bound,
      resetTimer: action.bound,
      reset: action.bound,
      logout: action.bound,
      authorize: action.bound,

      isAuthorizing: computed,
    });
  }

  get isAuthorizing(): boolean {
    return this.authorizingStage.isLoading;
  }

  private getErrorMessage = (code?: BaseResponseCodeType<ApiAuthError>): string => {
    return getErrorMessage<ApiAuthError>(code, errorMap);
  };

  setStep(value: AuthStep) {
    this.authStep.change(value);
  }

  changePhoneNumber = () => {
    this.setStep(AuthStep.initial);
  };

  async authorize(): Promise<BaseResponse> {
    if (this.authorizingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.authorizingStage.loading();

    const response = await api<UserServer>({
      url: apiUrls.user,
      method: 'GET',
    });

    if (response.isError) {
      this.authorizingStage.error();

      return { isError: true };
    }

    runInAction(() => {
      this.authorizingStage.success();
      this.userStore.setUser(UserModel.fromJson(response.data));
      this.analyticsStore.trackEvent({ event: AnalyticsEvent.userLogin });
    });

    return { isError: false };
  }

  async register(): Promise<BaseResponse> {
    if (this.phone.validate() || this.registerStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.registerStage.loading();

    const response = await api<{ timeout: number }, ApiAuthError>({
      url: apiUrls.phone.send,
      method: 'POST',
      data: {
        phone: this.phone.value,
      },
    });

    if (response.isError) {
      this.registerStage.error();

      this.phone.changeError(this.getErrorMessage(response.data?.code));

      return { isError: true };
    }

    runInAction(() => {
      this.registerStage.success();
      this.secondsCounter.change(response.data.timeout + 1);
      this.setStep(AuthStep.confirm);
      this.startSecondsCounter();
    });

    return { isError: false };
  }

  startSecondsCounter() {
    if (this.timer.value) {
      clearTimeout(this.timer.value);
    }

    const timer = setInterval(() => {
      this.secondsCounter.change(this.secondsCounter.value > 0 ? this.secondsCounter.value - 1 : 0);

      if (this.secondsCounter.value === 0) {
        clearTimeout(timer);
      }
    }, 1000);

    this.timer.change(timer);
  }

  async checkCode(): Promise<BaseResponse> {
    if (this.checkCodeStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.checkCodeStage.loading();

    const response = await api<UserServer, ApiAuthError>({
      url: apiUrls.phone.confirm,
      method: 'POST',
      data: {
        phone: this.phone.value,
        code: this.code.value,
      },
    });

    if (response.isError) {
      this.checkCodeStage.error();

      this.code.changeError(this.getErrorMessage(response.data?.code));

      return { isError: true };
    }

    runInAction(() => {
      this.checkCodeStage.success();
      this.setStep(AuthStep.initial);
      this.popupController.change(false);
      this.userStore.setUser(UserModel.fromJson(response.data));
      this.analyticsStore.trackEvent({ event: AnalyticsEvent.userLogin });
    });

    return { isError: false };
  }

  async getCode(): Promise<BaseResponse> {
    if (this.codeLoadingStage.isLoading) {
      return { isError: true };
    }

    this.codeLoadingStage.loading();

    const response = await api<{ timeout: number }, ApiAuthError>({
      url: apiUrls.phone.send,
      method: 'POST',
      data: {
        phone: this.phone.value,
      },
    });

    if (response.isError) {
      this.codeLoadingStage.error();

      this.code.changeError(this.getErrorMessage(response.data?.code));

      return { isError: true };
    }

    runInAction(() => {
      this.codeLoadingStage.success();
      this.secondsCounter.change(response.data.timeout + 1);
      this.startSecondsCounter();
    });

    return { isError: false };
  }

  async logout(): Promise<BaseResponse> {
    if (this.logoutStage.isLoading) {
      return { isError: true };
    }

    this.logoutStage.loading();

    const response = await api({
      url: apiUrls.logout,
      method: 'POST',
    });

    if (response.isError) {
      this.logoutStage.error();
      return { isError: true };
    }

    runInAction(() => {
      this.logoutStage.success();
      this.userStore.resetUser();
      this.reset();
    });

    return { isError: false };
  }

  resetTimer() {
    if (this.timer.value) {
      clearTimeout(this.timer.value);
    }

    this.timer.change(null);
    this.secondsCounter.change(SECONDS_TO_GET_NEW_CODE);
  }

  reset() {
    this.phone.change(null);
    this.code.change('');
    this.setStep(AuthStep.initial);
    this.resetTimer();
  }
}
