export enum RouteSegment {
  // "Каталог объектов"
  lots = 'lots',
  // "Личный кабинет"
  profile = 'profile',
}

export enum ProfileListType {
  favorites = 'favorites',
  bids = 'bids',
  subs = 'subs',
}
