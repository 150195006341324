import { AxiosRequestConfig, Method } from 'axios';

export enum LoadingStage {
  notStarted = 'notStarted',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export type BaseResponse<D = undefined, E = undefined> =
  | (E extends undefined
      ? {
          isError: true;
        }
      : {
          isError: true;
          data: E;
        })
  | (D extends undefined
      ? {
          isError: false;
        }
      : {
          isError: false;
          data: D;
        });

export enum BaseResponseCode {
  ok = 'ok',
  forbidden = 'forbidden',
  notFound = 'not_found',
  notAuthorized = 'not_authorized',
  badRequest = 'bad_request',
  conflict = 'conflict',
}

export type BaseResponseCodeType<C extends string = string> = BaseResponseCode & C;

enum ApiCallStatus {
  ok = 'ok',
  error = 'error',
}

export type ApiCallErrorRaw<C extends string = string, D extends Record<string, any> = Record<string, any>> = {
  status: ApiCallStatus.error;
  data: D;
  code: BaseResponseCodeType<C>;
  message: string;
};

export type ApiCallResponseRaw<D extends Record<string, any> = Record<string, any>> = {
  status: ApiCallStatus.ok;
  data: D;
};

export type ApiCallConfig<R extends Record<string, any> = Record<string, any>> = {
  url: string;
  method?: Method;
  data?: Record<string, any>;
  config?: AxiosRequestConfig;
  multipartFormData?: boolean;
  dangerouslyUsingMock?: {
    data: R;
    timeout?: number;
  };
  showError?: boolean;
};

export type ApiCallResponse<
  ResponseData extends Record<string, any> = object,
  ErrorCode extends string = string,
  ErrorData extends Record<string, any> = object,
> =
  | {
      isError: false;
      status: number;
      data: ResponseData;
      rawData: any;
    }
  | {
      isError: true;
      isCancelled: boolean;
      data:
        | ({
            responseCode: number;
          } & Omit<ApiCallErrorRaw<ErrorCode, ErrorData>, 'status'>)
        | null;
    };
