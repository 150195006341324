import { Tag, TagProps, Tooltip } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { FilterOption } from 'entities/lotFilters';
import { getIsElementOverflow } from 'utils/getIsElementOverflow';

import s from './SelectedItem.module.scss';

type SelectedItemProps = TagProps & {
  option: FilterOption;
  onChangeItem: (id: FilterOption['id']) => void;
  className?: string;
};

const SelectedItem = ({
  option,
  onChangeItem,
  className,
  ...rest
}: SelectedItemProps): React.ReactElement<SelectedItemProps> => {
  const handleClose = React.useCallback(() => onChangeItem(option.id), [onChangeItem, option]);

  const ref = React.useRef<HTMLDivElement | null>(null);
  const [isOverflow, setIsOverflow] = React.useState<boolean>(getIsElementOverflow(ref));

  React.useEffect(() => {
    setIsOverflow(getIsElementOverflow(ref));
  }, [ref.current]);

  return (
    <Tooltip title={isOverflow ? option.title : null} mouseEnterDelay={0.3}>
      <Tag className={cn(s['selected-item'], className)} closeIcon onClose={handleClose} {...rest}>
        <div className={s['selected-item__text']} ref={ref}>
          {option.title}
        </div>
      </Tag>
    </Tooltip>
  );
};

export default React.memo(SelectedItem);
