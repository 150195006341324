import { runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { Organizer } from 'entities/organizer';
import { FilterModelMulti } from 'models/FilterModelMulti';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ValueModel } from 'models/ValueModel';
import { BaseResponse } from 'types/meta';
import { api } from 'utils/api';

import { OrganizerValue } from '../types';

export class OrganizerFilterModel {
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  readonly organizer = new FilterModelMulti();
  readonly organizerInitValue = new ValueModel<OrganizerValue>([]);

  fetchOptions = async (): Promise<BaseResponse> => {
    if (this.loadingStage.isLoading) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const response = await api<{ items: Organizer[] }>({
      url: apiUrls.organizers,
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    runInAction(() => {
      this.organizer.setOptions(response.data.items, this.organizerInitValue.value);
      this.loadingStage.success();
    });

    return { isError: false };
  };
}
