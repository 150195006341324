import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import cn from 'classnames';
import * as React from 'react';

import s from './FullScreenButton.module.scss';

type FullScreenButtonProps = {
  onClick: React.MouseEventHandler<HTMLElement>;
  isFullscreen: boolean;
};

const FullScreenButton: React.FC<FullScreenButtonProps> = ({ onClick, isFullscreen }) => {
  if (isFullscreen) {
    return <FullscreenExitOutlined className={cn(s.button, s.button_fullscreen)} onClick={onClick} />;
  }

  return <FullscreenOutlined className={s.button} onClick={onClick} />;
};

export default React.memo(FullScreenButton);
