import { Tooltip as TooltipAnt, TooltipProps } from 'antd';
import * as React from 'react';

import useToggleState from 'hooks/useToggleState';

type TooltipPropsCustom = TooltipProps &
  React.PropsWithChildren<{
    title: any;
    className?: string;
  }>;

const Tooltip = ({
  title,
  className,
  children,
  ...rest
}: TooltipPropsCustom): React.ReactElement<TooltipPropsCustom> => {
  const { opened, open, close } = useToggleState();

  return (
    <div className={className} onMouseEnter={open} onClick={close} onMouseLeave={close}>
      <TooltipAnt {...rest} title={opened ? title : ''} open={opened}>
        {children}
      </TooltipAnt>
    </div>
  );
};

export default Tooltip;
