import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Tabbar } from 'components/Tabbar';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type ObjectTypeTabbarProps = {
  className?: string;
};

const ObjectTypeTabbar = ({ className }: ObjectTypeTabbarProps): React.ReactElement<ObjectTypeTabbarProps> | null => {
  const { filters } = useLotListStore();
  const { selectedValue, tabsOptions } = filters.aggregatedType;

  const handleObjectTypeChange = React.useCallback(
    (value: string) => {
      filters.setObjectType(value);
    },
    [filters.setObjectType],
  );

  return (
    <Tabbar
      className={className}
      selected={selectedValue.value}
      tabsOptions={tabsOptions}
      onChange={handleObjectTypeChange}
      size="middle"
    />
  );
};

export default observer(ObjectTypeTabbar);
