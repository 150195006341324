import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { TotalItemsCount as TotalItemsCountBase } from 'components/TotalItemsCount';
import { totalItemsPrefixOptions } from 'stores/LotListProfileStore/LotListProfileFiltersModel/config';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

type TotalItemsCountProps = {
  className?: string;
};

const TotalItemsCount = ({ className }: TotalItemsCountProps): React.ReactElement<TotalItemsCountProps> => {
  const {
    currentTotalItems,
    filters: { listType },
  } = useLotListProfileStore();

  return (
    <TotalItemsCountBase
      className={className}
      itemsCount={currentTotalItems}
      prefix={totalItemsPrefixOptions[listType.selectedValue.value](currentTotalItems)}
    />
  );
};

export default observer(TotalItemsCount);
