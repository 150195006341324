import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Sort as SortBase } from 'components/Sort';
import DropdownSort from 'components/Sort/DropdownSort';
import { useWidth } from 'hooks/useWidth';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type SortProps = {
  className?: string;
};

const Sort = ({ className }: SortProps): React.ReactElement<SortProps> | null => {
  const store = useLotListStore();
  const { filters } = store;
  const { options, selectedValue } = filters.sortOption;
  const { isDesktop } = useWidth();

  if (isDesktop) {
    return (
      <SortBase
        className={className}
        options={options}
        selected={selectedValue.value}
        sortDirection={filters.sortDirection.value}
        onClick={filters.setSortValue}
      />
    );
  }

  return <DropdownSort className={className} onClick={filters.setSortValue} />;
};

export default observer(Sort);
